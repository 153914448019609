import { UPSELL_CART_FRAGMENT } from '@/graphql/Fragments/cart'
import { gql } from '@apollo/client'

export const APPLY_PROMOTION_MUTATION = gql`
  mutation applyPromotion($token: String!) {
    applyPromotion(token: $token) {
      currentCart {
        ...UpsellCartFragment
      }
    }
  }
  ${UPSELL_CART_FRAGMENT}
`

export const REMOVE_PROMOTION_MUTATION = gql`
  mutation removePromotion {
    removePromotion {
      currentCart {
        ...UpsellCartFragment
      }
    }
  }
  ${UPSELL_CART_FRAGMENT}
`
