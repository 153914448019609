import { TailwindConfig, useWindowDimensions } from '@sedomicilier/material-sd'

export const phoneNumber = '01 83 64 31 07'
export const phoneHref = 'tel:+33183643107'

const PhoneLink: React.FC = () => {
  const { width } = useWindowDimensions()

  const getLabel = () => {
    if (
      width! >= parseInt(TailwindConfig.breakpointsCustomerArea.desktop.min, 10)
    ) {
      return `Appel gratuit ${phoneNumber}`
    } else if (
      width! >= parseInt(TailwindConfig.breakpointsCustomerArea.tablet.min, 10)
    ) {
      return phoneNumber
    }
    return
  }

  return (
    <a data-testid='phone-link' href={phoneHref} className='link-phone body-2'>
      <span className='ico-phone text-4' />
      {getLabel()}
    </a>
  )
}

export default PhoneLink
