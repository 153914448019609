import React, { ReactNode } from 'react'

interface PaymentTitleProps {
  title: string
  subtitle?: string
  icon?: ReactNode
}

const PaymentTitle: React.FC<PaymentTitleProps> = ({
  title,
  subtitle,
  icon
}) => {
  return (
    <div className='mb-3'>
      <h2 className='title-3 !font-bold !text-2'>{title}</h2>
      <div className='flex items-center gap-1'>
        {icon && icon}
        <h3 className='body-2 !text-neutral-600'>{subtitle}</h3>
      </div>
    </div>
  )
}

export default PaymentTitle
