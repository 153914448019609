import { UPSELL_CART_FRAGMENT } from '@/graphql/Fragments/cart'
import { gql } from '@apollo/client'

export const UPSELL_APPLY_BILLING_CYCLE = gql`
  mutation upsellApplyBillingCycle($billingCycle: String!) {
    upsellApplyBillingCycle(billingCycle: $billingCycle) {
      currentCart {
        ...UpsellCartFragment
      }
    }
  }
  ${UPSELL_CART_FRAGMENT}
`
