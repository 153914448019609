import { BasicLink } from '@sedomicilier/material-sd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

const Failure: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/loyalty/payment/billing')
    }, 3000)
  }, [])

  return (
    <div className='w-full flex flex-col items-center'>
      <span className='ico-warning-triangle text-yellow-500 text-[80px]' />
      <h1 className='title-1 mt-3'>{t('payments:processing.failure.title')}</h1>
      <p className='body-1 mt-4'>{t('payments:processing.failure.subtitle')}</p>
      <Link to='/loyalty/payment/billing'>
        <BasicLink
          color='black'
          hoverUnderline
          label={t('payments:processing.failure.redirect')}
          className='mt-2'
        />
      </Link>
    </div>
  )
}

export default Failure
