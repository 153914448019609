import BillingComponent from '@/components/payment/billing'
import { ContactContext } from '@/contexts/contactContext'
import { UPSELL_CURRENT_CART } from '@/graphql/queries/upsellCarts'
import { IPaymentSource } from '@/interfaces/paymentSource'
import { useQuery } from '@apollo/client'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { Spin } from 'antd'
import { useContext, useState } from 'react'

export interface BillingDetails {
  cardName?: string
  paymentSource?: string
}

interface BillingProps {
  clientSecret: string | undefined
}

const Billing: React.FC<BillingProps> = ({ clientSecret }) => {
  const { contact } = useContext(ContactContext)
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState(false)

  const cards = contact.paymentSources.filter(
    (paymentSource: IPaymentSource) => paymentSource.sourceType === 'card'
  )

  const defaultCard = cards.find(
    (card: IPaymentSource) => card.currentSource
  )?.token

  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const { data: upsellCartData, loading: upsellCartLoading } =
    useQuery(UPSELL_CURRENT_CART)

  if (upsellCartLoading) {
    return (
      <div className='absolute top-[50%] left-[50%]'>
        <Spin size='large' />
      </div>
    )
  }

  const onFinish = async (values: BillingDetails) => {
    setLoading(true)

    const { paymentSource, cardName } = values

    if (!stripe || !clientSecret) return

    if (!paymentSource && elements) {
      const submitResult = await elements.submit()
      if (submitResult.error) {
        setErrorMessage(submitResult.error.message)
        return
      }
    }

    const confirm = () => {
      if (paymentSource) {
        return stripe.confirmPayment({
          clientSecret: clientSecret,
          confirmParams: {
            return_url: window.location.href.replace(/\/[^/]*$/, '/processing'),
            payment_method: paymentSource
          }
        })
      } else if (cardName && elements) {
        return stripe.confirmPayment({
          clientSecret: clientSecret,
          elements: elements,
          confirmParams: {
            return_url: window.location.href.replace(/\/[^/]*$/, '/processing'),
            payment_method_data: {
              billing_details: { name: values.cardName }
            }
          }
        })
      }
      throw new Error('No valid payment method provided')
    }

    const { error } = await confirm()

    setLoading(false)

    if (error?.message) {
      setErrorMessage(error.message)
    } else {
      setErrorMessage('An unexpected error occurred.')
    }
  }

  const initialValues = {
    paymentSource: defaultCard
  }

  return (
    <div className='w-full'>
      <BillingComponent
        loading={loading}
        errorMessage={errorMessage}
        htAmount={upsellCartData?.upsellCurrentCart.amount}
        deductibleVAT={upsellCartData?.upsellCurrentCart.amountVat}
        ttcAmount={upsellCartData?.upsellCurrentCart.amountWithVat}
        onFinish={onFinish}
        initialValues={initialValues}
        cards={cards}
      />
    </div>
  )
}

export default Billing
