import { BasicButton, CommonSection } from '@sedomicilier/material-sd'
import { useTranslation } from 'react-i18next'
import OptionsReminder from '@/app/Loyalty/Offer/optionsReminder'
import { useContext, useEffect } from 'react'
import { DomiciliationContext } from '@/contexts/DomiciliationContext'
import { ContactContext } from '@/contexts/contactContext'
import { nextPage } from '@/reducers/loyaltyFlow'
import { useDispatch } from '@/hooks/redux'
import { UPSELL_FREQUENCY_UPDATE } from '@/graphql/mutations/upsellInitializeCart'
import { useMutation } from '@apollo/client'
import { Spin } from 'antd'

const Offer: React.FC = () => {
  const { t } = useTranslation('upsell')
  const dispatch = useDispatch()

  const { domiciliation } = useContext(DomiciliationContext)
  const { contact } = useContext(ContactContext)

  const [upsellFrequencyUpdate, { loading }] = useMutation(
    UPSELL_FREQUENCY_UPDATE
  )

  const domiciliationPrice = domiciliation.addressSubscription.amount
  const paymentType = domiciliation.addressSubscription.paymentType

  useEffect(() => {
    upsellFrequencyUpdate()
  }, [])

  if (loading) {
    return (
      <div className='absolute top-[50%] left-[50%]'>
        <Spin size='large' />
      </div>
    )
  }

  return (
    <CommonSection title={t('loyalty.offer.title')}>
      <p className='mb-4'>{t('loyalty.offer.trust')}</p>

      <OptionsReminder
        society={contact.society}
        contact={contact}
        domiciliation={domiciliation}
        domiciliationPrice={t('common:pricing.€ByPeriodHT', {
          price: domiciliationPrice,
          period: t('common:paymentType.' + paymentType)
        })}
      />
      <div className='py-3 flex justify-end'>
        <BasicButton
          testid='offer-next-button'
          onClick={() => dispatch(nextPage())}
          label={t('common:next')}
          bgColor='blue'
          className='font-semibold'
          withArrow
        />
      </div>
    </CommonSection>
  )
}

export default Offer
