import { ReactNode } from 'react'

interface PaymentBlockProps {
  children: ReactNode
}

const PaymentBlock: React.FC<PaymentBlockProps> = ({ children }) => {
  return (
    <div className='bg-neutral-0 p-4 tablet:w-[440px] mobile:w-full rounded-medium h-fit'>
      {children}
    </div>
  )
}

export default PaymentBlock
