import { Frequency, ICart } from '@/interfaces/cart'
import { Tags } from '@sedomicilier/material-sd'
import { Radio } from 'antd'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

interface UpsellFrequenciesProps {
  allCarts: ICart[]
  frequency: string | undefined
  setFrequency: Dispatch<SetStateAction<Frequency | undefined>>
}

const UpsellFrequencies: React.FC<UpsellFrequenciesProps> = ({
  allCarts,
  frequency,
  setFrequency
}) => {
  const { t } = useTranslation('payments')

  const onChange = (e) => {
    setFrequency(e.target.value)
  }

  return (
    <Radio.Group
      defaultValue={frequency}
      onChange={onChange}
      className='flex flex-col gap-3 py-4'
    >
      {allCarts.map((cart: ICart, index) => {
        const commitmentPercent = cart.purchases.find(
          (purchase) => purchase.category === 'commitment'
        )?.commitmentPercent

        const paymentType = cart.paymentType
        return (
          <Radio
            data-testId={`subscription-${paymentType}`}
            key={index}
            value={paymentType}
            className='body-1 flex items-start w-full'
          >
            <div>
              <p className='font-medium flex items-center gap-1'>
                {t(`summary.paymentFrequencies.frequency.${paymentType}`)}
                <Tags
                  bgColor='green'
                  label={t('summary.paymentFrequencies.saveUpTo', {
                    saved: -cart.totalReduction
                  })}
                  className='text-6'
                />
              </p>
              <span className='body-2 !text-neutral-500'>
                {t('summary.paymentFrequencies.commitmentPercent', {
                  count: commitmentPercent
                })}
              </span>
            </div>
          </Radio>
        )
      })}
    </Radio.Group>
  )
}

export default UpsellFrequencies
