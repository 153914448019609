import { gql } from '@apollo/client'

export const STRIPE_ACCOUNT_QUERY = gql`
  query stripeAccount {
    stripeAccount
  }
`

export const STRIPE_INFORMATIONS = gql`
  query stripeInformations {
    stripeInformations {
      sepaCountryWithOwnerAddressNeeded
    }
  }
`

export const STRIPE_SETUP_INTENT_SECRET = gql`
  query setupIntentSecret {
    setupIntentSecret
  }
`

export const PAYMENT_INFORMATIONS_QUERY = gql`
  query paymentInformations {
    paymentInformations {
      stripeAccount
      clientSecret
    }
  }
`
