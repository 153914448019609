import { useTranslation } from 'react-i18next'
import SumUpPurchase from '@/components/payment/summary/sumUpPurchase'
import { SumUpPurchases } from '@/app/Loyalty/Payment/summary'

interface SumUpProps {
  total: number
  purchases: SumUpPurchases
}

const SumUp: React.FC<SumUpProps> = ({ total, purchases }) => {
  const { t } = useTranslation('payments')
  return (
    <div>
      <div className='px-2 py-1 bg-neutral-100 rounded-t-medium body-1 flex justify-between'>
        <span className='font-medium'>{t('summary.order.toPay')}</span>
        <span className='font-semibold'>
          {t('summary.order.€woVat', {
            price: total.toFixed(2).toString().replace('.', ',')
          })}
        </span>
      </div>
      <div className='p-2 border border-neutral-200 rounded-b-medium body-2 flex flex-col gap-2'>
        {purchases.map((purchase, index) => (
          <SumUpPurchase
            key={index}
            title={purchase.title}
            subtitle={purchase.subtitle}
            className={purchase.className}
            pricing={purchase.pricing}
            testId={purchase.testId}
          />
        ))}
      </div>
    </div>
  )
}

export default SumUp
