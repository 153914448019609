import { UPSELL_CART_FRAGMENT } from '@/graphql/Fragments/cart'
import { gql } from '@apollo/client'

export const UPSELL_CURRENT_CART = gql`
  query upsellCurrentCart {
    upsellCurrentCart {
      ...UpsellCartFragment
    }
  }
  ${UPSELL_CART_FRAGMENT}
`

export const UPSELL_ALL_CARTS = gql`
  query upsellAllCarts {
    upsellAllCarts {
      ...UpsellCartFragment
    }
  }
  ${UPSELL_CART_FRAGMENT}
`
