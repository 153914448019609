import { ReactNode } from 'react'

interface SumUpPurchaseProps {
  title: string
  pricing?: ReactNode
  subtitle?: string
  className?: string
  testId?: string
}

const SumUpPurchase: React.FC<SumUpPurchaseProps> = ({
  title,
  subtitle,
  pricing,
  className,
  testId
}) => {
  return (
    <div className={className} data-testid={testId}>
      <div className='font-semibold flex justify-between'>
        {title}
        {pricing && <span>{pricing}</span>}
      </div>
      {subtitle && <span className='text-neutral-600 text-5'>{subtitle}</span>}
    </div>
  )
}

export default SumUpPurchase
