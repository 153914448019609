import Billing from '@/app/Loyalty/Payment/billing'
import StripeLayout from '@/components/stripeLayout'
import { CurrentUserContext } from '@/contexts/currentUserContext'
import { PAYMENT_INFORMATIONS_QUERY } from '@/graphql/queries/stripe'
import { IPaymentInformations } from '@/interfaces/stripe'
import { useQuery } from '@apollo/client'
import { Spin } from 'antd'
import { useContext } from 'react'

interface BillingLayoutProps {}

const BillingLayout: React.FC<BillingLayoutProps> = () => {
  const { locale } = useContext(CurrentUserContext)

  const { data, loading } = useQuery<{
    paymentInformations: IPaymentInformations
  }>(PAYMENT_INFORMATIONS_QUERY, { fetchPolicy: 'no-cache' })

  const paymentInformations = {
    stripeAccount: data?.paymentInformations.stripeAccount,
    clientSecret: data?.paymentInformations.clientSecret
  }

  if (loading) {
    return (
      <div className='absolute top-[50%] left-[50%]'>
        <Spin size='large' />
      </div>
    )
  }
  return (
    <StripeLayout paymentInformations={paymentInformations} locale={locale}>
      <Billing clientSecret={paymentInformations.clientSecret} />
    </StripeLayout>
  )
}

export default BillingLayout
