import gql from 'graphql-tag'
import { LEGAL_DOCUMENT_FRAGMENT } from '../Fragments'

export const LEGAL_DOCUMENT_UPDATE_MUTATION = gql`
  mutation LegalDocumentUpdate(
    $legalDocumentId: ID!
    $fileRecto: Upload!
    $fileVerso: Upload
  ) {
    legalDocumentUpdate(
      legalDocumentId: $legalDocumentId
      fileRecto: $fileRecto
      fileVerso: $fileVerso
    ) {
      legalDocument {
        ...LegalDocumentFragment
      }
    }
  }
  ${LEGAL_DOCUMENT_FRAGMENT}
`

export const LEGAL_DOCUMENT_CREATE_MUTATION = gql`
  mutation LegalDocumentCreate(
    $fileType: String!
    $fileRecto: Upload!
    $fileVerso: Upload
    $targetId: ID!
    $targetType: String!
  ) {
    legalDocumentCreate(
      fileType: $fileType
      fileRecto: $fileRecto
      fileVerso: $fileVerso
      targetId: $targetId
      targetType: $targetType
    ) {
      legalDocument {
        ...LegalDocumentFragment
      }
    }
  }
  ${LEGAL_DOCUMENT_FRAGMENT}
`

export const FORMALITY_CAPITAL_DEPOSIT_UPLOAD = gql`
  mutation FormalityCapitalDepositUpload(
    $id: ID!
    $legalDocumentId: ID
    $fileType: String!
    $fileRecto: Upload!
    $fileVerso: Upload
  ) {
    formalityCapitalDepositUpload(
      id: $id
      legalDocumentId: $legalDocumentId
      fileType: $fileType
      fileRecto: $fileRecto
      fileVerso: $fileVerso
    ) {
      legalDocument {
        id
        fileType
        fileRecto
        fileVerso
        state
      }
    }
  }
`
