import { UPSELL_CART_FRAGMENT } from '@/graphql/Fragments/cart'
import { gql } from '@apollo/client'

export const UPSELL_FREQUENCY_UPDATE = gql`
  mutation upsellInitializeCart {
    upsellInitializeCart {
      currentCart {
        ...UpsellCartFragment
      }
    }
  }
  ${UPSELL_CART_FRAGMENT}
`
