import Cgv from '@/components/payment/billing/cgv'
import PaymentAmounts from '@/components/payment/billing/paymentAmounts'
import PaymentTitle from '@/components/payment/billing/paymentTitle'
import PaymentBlock from '@/components/payment/paymentBlock'
import { IPaymentSource } from '@/interfaces/paymentSource'
import { BasicButton, NoticeInformation } from '@sedomicilier/material-sd'
import { Divider } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface PaymentValidationProps {
  htAmount: number
  deductibleVAT: number
  ttcAmount: number
  errorMessage?: string
  loading: boolean
  cards: IPaymentSource[]
}

const PaymentValidation: React.FC<PaymentValidationProps> = ({
  htAmount,
  deductibleVAT,
  ttcAmount,
  errorMessage,
  loading,
  cards
}) => {
  const { t } = useTranslation()

  const [checked, setChecked] = useState(false)

  const haveOneCard = cards.length > 0

  return (
    <PaymentBlock>
      <PaymentTitle title={t('payments:billing.validation.title')} />
      <PaymentAmounts
        htAmount={htAmount}
        deductibleVAT={deductibleVAT}
        ttcAmount={ttcAmount}
      />

      {errorMessage && (
        <span className='block text-error-500 font-bold my-2'>
          {errorMessage}
        </span>
      )}

      <Divider />
      <Cgv checked={checked} setChecked={setChecked} />
      <BasicButton
        testid='billing-validation-button'
        label={t('payments:billing.validation.confirm')}
        className='w-full justify-center !py-1 mb-3'
        bgColor='blue'
        disabled={!checked && haveOneCard}
        loading={loading}
      />
      <NoticeInformation bgColor='yellow'>
        <p className='body-2 font-bold'>
          {t('upsell:loyalty.offer.asAReminder')}
        </p>
        <p className='body-2'>{t('upsell:loyalty.offer.notCumulative')}</p>
      </NoticeInformation>
    </PaymentBlock>
  )
}

export default PaymentValidation
