import PaymentTitle from '@/components/payment/billing/paymentTitle'
import PaymentBlock from '@/components/payment/paymentBlock'
import UpsellFrequencies from '@/components/payment/summary/upsellFrequencies'
import { ContactContext } from '@/contexts/contactContext'
import { Frequency, ICart } from '@/interfaces/cart'
import { NoticeInformation } from '@sedomicilier/material-sd'
import { Dispatch, SetStateAction, useContext } from 'react'
import { useTranslation } from 'react-i18next'

interface PaymentFrequencyProps {
  allCarts: ICart[]
  frequency: string | undefined
  setFrequency: Dispatch<SetStateAction<Frequency | undefined>>
}

const PaymentFrequency: React.FC<PaymentFrequencyProps> = ({
  allCarts,
  frequency,
  setFrequency
}) => {
  const { t } = useTranslation()

  const { contact } = useContext(ContactContext)
  return (
    <PaymentBlock>
      <PaymentTitle
        title={t('payments:summary.paymentFrequencies.title')}
        subtitle={t('payments:summary.paymentFrequencies.subtitle')}
      />

      <NoticeInformation
        bgColor='blue'
        icon={<span className='ico-card text-l text-primary-500' />}
      >
        <p
          className='[&>span]:font-bold'
          dangerouslySetInnerHTML={{
            __html: t('payments:summary.paymentFrequencies.notice', {
              societyName: `${contact.society.name} ${contact.society.legalStatus.name}`
            })
          }}
        />
      </NoticeInformation>

      <UpsellFrequencies
        allCarts={allCarts}
        frequency={frequency}
        setFrequency={setFrequency}
      />

      <p className='font-inter text-6 text-green-700 font-medium'>
        {t('payments:summary.paymentFrequencies.appliedDiscount')}
      </p>
    </PaymentBlock>
  )
}

export default PaymentFrequency
