export interface ModuleType {
  name: string
  path: string
  nextPage: (option?: string) => ModuleType | undefined
}

export const Offer: ModuleType = {
  name: 'offer',
  path: '/loyalty/offer',
  nextPage: () => PaymentSummary
}

export const PaymentSummary: ModuleType = {
  name: 'paymentSummary',
  path: '/loyalty/payment/summary',
  nextPage: () => Billing
}

export const Billing: ModuleType = {
  name: 'billing',
  path: '/loyalty/payment/billing',
  nextPage: () => undefined
}

export const loyaltyModules: ModuleType[] = [Offer, PaymentSummary, Billing]

export const entryModule = Offer
