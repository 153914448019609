import gql from 'graphql-tag'

export const LEGAL_FORMALITY_FRAGMENT = gql`
  fragment LegalFormalityFragment on LegalFormality {
    id
    identificationToken
    status
    step
    steps
    formUrl
    formProvider
    capitalDepositDocument {
      id
      fileType
      targetId
      fileRecto
      fileVerso
      state
    }
    isPartnerDeposit
  }
`
