import { useTranslation } from 'react-i18next'

interface PaymentAmountsProps {
  htAmount: number
  deductibleVAT: number
  ttcAmount: number
}

const PaymentAmounts: React.FC<PaymentAmountsProps> = ({
  htAmount,
  deductibleVAT,
  ttcAmount
}) => {
  const { t } = useTranslation()

  const formatNumber = (amount: number) => {
    return amount % 1 === 0
      ? amount
      : amount.toFixed(2).toString().replace('.', ',')
  }

  return (
    <div className='body-1 font-medium flex flex-col gap-1'>
      <div className='flex justify-between text-neutral-500'>
        <span>{t('payments:billing.validation.paymentAmounts.htAmount')}</span>
        <span>
          {t('payments:billing.validation.paymentAmounts.€', {
            amount: formatNumber(htAmount)
          })}
        </span>
      </div>
      <div className='flex justify-between text-neutral-500'>
        <span>
          {t('payments:billing.validation.paymentAmounts.deductibleVAT')}
        </span>
        <span>
          {t('payments:billing.validation.paymentAmounts.€', {
            amount: formatNumber(deductibleVAT)
          })}
        </span>
      </div>
      <div className='flex justify-between text-2 font-bold'>
        <span>{t('payments:billing.validation.paymentAmounts.ttcAmount')}</span>
        <span>
          {t('payments:billing.validation.paymentAmounts.€', {
            amount: formatNumber(ttcAmount)
          })}
        </span>
      </div>
    </div>
  )
}

export default PaymentAmounts
