import UpsellPaymentProcessing from '@/app/Loyalty/Payment/processing'
import StripeLayout from '@/components/stripeLayout'
import { CurrentUserContext } from '@/contexts/currentUserContext'
import { STRIPE_ACCOUNT_QUERY } from '@/graphql/queries/stripe'
import { useQuery } from '@apollo/client'
import { Spin } from 'antd'
import { useContext } from 'react'

interface ProcessingLayoutProps {}

const ProcessingLayout: React.FC<ProcessingLayoutProps> = () => {
  const { locale } = useContext(CurrentUserContext)

  const { data, loading } = useQuery<{
    stripeAccount: string
  }>(STRIPE_ACCOUNT_QUERY)

  if (loading) {
    return (
      <div className='absolute top-[50%] left-[50%]'>
        <Spin size='large' />
      </div>
    )
  }

  const paymentInformations = {
    stripeAccount: data?.stripeAccount
  }

  return (
    <StripeLayout paymentInformations={paymentInformations} locale={locale}>
      <UpsellPaymentProcessing />
    </StripeLayout>
  )
}

export default ProcessingLayout
