import { ReactNode } from 'react'
import { BasicLink } from '@sedomicilier/material-sd'
import { useTranslation } from 'react-i18next'

interface OptionProps {
  icoName: string
  title?: string
  subtitle?: string
  price?: ReactNode
  modifyOption?: boolean
  setModify?: () => void
  testid?: string
}

const Option: React.FC<OptionProps> = ({
  icoName,
  title,
  subtitle,
  price,
  modifyOption,
  setModify,
  testid
}) => {
  const { t } = useTranslation()

  return (
    <div
      className='flex gap-2 body-2 mobile:items-start tablet:items-center justify-center'
      data-testid={testid || 'summary-option'}
    >
      <span
        className={`${icoName} text-4 bg-neutral-0 p-[12px] rounded-medium`}
      />
      <div className='flex flex-1 justify-between mobile:flex-col'>
        <div>
          <h4 className='font-semibold text-neutral-700'>{title}</h4>
          <p translate='no' className='text-neutral-500'>
            {subtitle}
          </p>
        </div>
        {price}
        {modifyOption && (
          <BasicLink
            color='blue'
            label={t('common.update')}
            className='!text-interaction font-semibold'
            hoverUnderline
            onClick={setModify}
          />
        )}
      </div>
    </div>
  )
}

export default Option
