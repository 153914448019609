import gql from 'graphql-tag'
import { LEGAL_FORMALITY_FRAGMENT } from './legalFormality'

export const PURCHASE_FRAGMENT = gql`
  fragment PurchaseFragment on Purchase {
    amount
    category
    commitmentPercent
    id
    identificationToken
    legalFormality {
      ...LegalFormalityFragment
    }
    productId
    recurrent
    reductionAmount
    status
  }
  ${LEGAL_FORMALITY_FRAGMENT}
`
