import { BillingDetails } from '@/app/Loyalty/Payment/billing'
import CardDetails from '@/components/payment/billing/cardDetails'
import PaymentValidation from '@/components/payment/billing/paymentValidation'
import { IPaymentSource } from '@/interfaces/paymentSource'
import { Form } from 'antd'

interface BillingComponentProps {
  htAmount: number
  deductibleVAT: number
  ttcAmount: number
  onFinish: (values: BillingDetails) => void
  initialValues: { paymentSource: string | undefined }
  errorMessage?: string
  loading: boolean
  cards: IPaymentSource[]
}

const BillingComponent: React.FC<BillingComponentProps> = ({
  htAmount,
  deductibleVAT,
  ttcAmount,
  onFinish,
  initialValues,
  errorMessage,
  loading,
  cards
}) => {
  return (
    <Form
      requiredMark={false}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      className='flex gap-2 mobile:flex-col mobile:items-center tablet:flex-col tablet:items-center wide:flex-row wide:justify-center wide:items-start'
    >
      <CardDetails cards={cards} />
      <PaymentValidation
        cards={cards}
        loading={loading}
        errorMessage={errorMessage}
        htAmount={htAmount}
        deductibleVAT={deductibleVAT}
        ttcAmount={ttcAmount}
      />
    </Form>
  )
}

export default BillingComponent
