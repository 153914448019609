import { useTranslation } from 'react-i18next'
import Option from '@/app/Loyalty/Offer/option'
import { ISociety } from '@/interfaces/society'
import IContact from '@/interfaces/contact'
import IDomiciliation from '@/interfaces/domiciliation'

interface OptionsReminderProps {
  domiciliationPrice: string
  society: ISociety
  contact: IContact
  domiciliation: IDomiciliation
}

const OptionsReminder: React.FC<OptionsReminderProps> = ({
  contact,
  society,
  domiciliation,
  domiciliationPrice
}) => {
  const { t } = useTranslation('upsell')
  const fullName = `${contact.personalInformation.contactDetail.firstname} ${contact.personalInformation.contactDetail.lastname}`
  const fullAddress = domiciliation.address.fullAddress

  const options = [
    {
      testid: 'offer-option-domiciliation',
      icon: 'ico-d-ping',
      title: t('loyalty.offer.reminder.options.domiciliation.title', {
        legalStatus: society.legalStatus.name
      }),
      subtitle: fullAddress,
      price: (
        <div className='flex justify-end gap-0.5 mobile:justify-start'>
          <span className='font-semibold'>{domiciliationPrice}</span>
        </div>
      )
    },
    {
      testid: 'offer-option-leader-name',
      icon: 'ico-d-silhouette',
      title: t('loyalty.offer.reminder.options.legalRepresentative'),
      subtitle: fullName
    },
    {
      testid: 'offer-option-society-name',
      icon: 'ico-silhouettes',
      title: t('loyalty.offer.reminder.options.societyName'),
      subtitle: society.name
    }
  ]

  return (
    <div>
      <h3 className='title-3 mb-3'>{t('loyalty.offer.reminder.title')}</h3>
      <div className='bg-neutral-150 p-3 rounded-medium flex flex-col gap-2'>
        {options.map((option) => (
          <Option
            icoName={option.icon}
            title={option.title}
            subtitle={option.subtitle}
            price={option.price}
            key={option.testid}
            testid={option.testid}
          />
        ))}
      </div>
    </div>
  )
}

export default OptionsReminder
