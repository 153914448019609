import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { FORWARDING_PACKS } from '@/graphql/queries/products'
import { Button, Page, openValidationPopup } from 'src/components/UIKit'
import {
  ContentRow,
  PackContent,
  PackContainer,
  PackPrice,
  PackTitle
} from './styles'
import styled from 'styled-components'
import { Constants, Colors } from 'src/components/UIKit'
import { Col } from 'antd'
import { CART_ADD_PURCHASES } from '@/graphql/mutations/cart'

interface IPrettyPack {
  id: number
  title: string
  price: number
  priceText: string
  priceVatText: string
  description: string
  specialOfferTemplate: {
    freeQuantity: string
    id: string
  }
  count: number
}

const ForwardingPacks = () => {
  const { t } = useTranslation()
  /* eslint-disable @typescript-eslint/no-var-requires */
  const HtmlToReactParser = require('html-to-react').Parser
  const htmlToReactParser = new HtmlToReactParser()

  const [cartAddPurchases] = useMutation(CART_ADD_PURCHASES)

  const { loading, error, data } = useQuery(FORWARDING_PACKS)

  const addToCart = (pack: IPrettyPack) => {
    cartAddPurchases({ variables: { productIds: [pack.id] } }).then(() => {
      openValidationPopup(t('products:added'), pack.title)
    })
  }

  const packDescription = (pack: IPrettyPack) => {
    const unitsMatches: string[] = /^(\d+)/.exec(pack.title) || ['1']
    const units: number =
      Number.parseInt(unitsMatches[0], 10) +
      Number(pack.specialOfferTemplate?.freeQuantity || 0)
    const pricePerUnit: number = pack.price / 100 / units
    const description: string =
      units === 1
        ? t('subscription:forwarding_pack.unitPrice')
        : t('subscription:forwarding_pack.packDescription', {
            value: pricePerUnit
          })

    return description
  }

  const specialOfferTitle = htmlToReactParser.parse(
    data?.forwardingPacks
      ?.filter((obj) => obj.specialOfferTemplate?.title)[0]
      ?.specialOfferTemplate.title.replaceAll(`\r\n`, '')
  )
  const specialOfferDescription = htmlToReactParser.parse(
    data?.forwardingPacks
      ?.filter((obj) => obj.specialOfferTemplate?.description)[0]
      ?.specialOfferTemplate.description.replaceAll(`\r\n`, '')
  )

  return (
    <Page>
      <ContentRow>
        <UnborderedCard>
          <h3>{t('subscription:forwarding_pack.selectAPack')}</h3>

          {specialOfferTitle && specialOfferDescription ? (
            <SpecialOfferTitles>
              <SpecialOfferBold>{specialOfferTitle}</SpecialOfferBold>
              {specialOfferDescription}
            </SpecialOfferTitles>
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  'subscription:forwarding_pack.selectAPackDescription',
                  {
                    escapeValue: false
                  }
                )
              }}
            />
          )}
        </UnborderedCard>

        {loading && <>loading...</>}
        {error && <>error...</>}

        <PackContainer>
          {data?.forwardingPacks?.map((pack) => {
            return (
              <PackCard
                key={pack.id}
                bgColor={pack.specialOfferTemplate?.cardBackgroundColor}
                collectionHasSpecialOffer={specialOfferTitle}
                ctaBgColor={pack.specialOfferTemplate?.cardCtaBackgroundColor}
              >
                <PackContent>
                  {pack.specialOfferTemplate && (
                    <CardSpecialOfferTitle
                      titlecolor={pack.specialOfferTemplate.cardTitleColor}
                    >
                      {pack.specialOfferTemplate.cardTitleIcon && (
                        <img
                          src={pack.specialOfferTemplate.cardTitleIcon}
                          alt='icone promotionnelle'
                        />
                      )}
                      <span>{pack.specialOfferTemplate.cardTitle}</span>
                    </CardSpecialOfferTitle>
                  )}
                  {specialOfferTitle && !pack.specialOfferTemplate && (
                    <PackTitle>
                      <span>À l’unité</span>
                    </PackTitle>
                  )}
                  <h4>{t(`subscription:packList.${pack.title}`)}</h4>
                  {pack.specialOfferTemplate && (
                    <SpecialOfferFreeQuantity>
                      {t('subscription:christmas_offer.free_count', {
                        count: pack.specialOfferTemplate.freeQuantity
                      })}
                    </SpecialOfferFreeQuantity>
                  )}
                  <PackPrice>
                    {t('common:currency', {
                      value: (pack.price + pack.legalFees) / 100
                    })}
                  </PackPrice>
                  <span> {packDescription(pack)} </span>
                  <Button onClick={() => addToCart(pack)}>
                    {t('subscription:forwarding_pack.addToCart')}
                    {pack.specialOfferTemplate?.cardCtaIcon && (
                      <img
                        src={pack.specialOfferTemplate?.cardCtaIcon}
                        alt='icone promo'
                        className='inline'
                      />
                    )}
                  </Button>
                </PackContent>
              </PackCard>
            )
          })}
        </PackContainer>
      </ContentRow>
    </Page>
  )
}

export const PackCard = styled.div`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : `${Colors.lightBlueBackground}`};
  max-width: 265px;
  min-width: 225px;
  width: 265px;
  height: ${(props) => (props.collectionHasSpecialOffer ? '265px' : '215px')};
  padding: 16px;
  border-radius: 4px;

  @media (min-width: ${Constants.breakpoints['large-breakpoint-o']}) {
    margin-right: 25px;
  }

  @media (max-width: ${Constants.breakpoints['large-breakpoint-i']}) {
    margin-bottom: 25px;
  }

  &:last-child {
    margin: 0;
  }

  ${(props) =>
    props.ctaBgColor &&
    `
    button {
      background-color: ${props.ctaBgColor};
      margin-top: 26px;
      img {
        width: 20px;
        margin-left: 5px;
      }
    }

  `}
`

export const UnborderedCard = styled.div`
  max-width: 550px;
  margin-bottom: 30px;
`
export const SpecialOfferTitles = styled.div`
  p {
    margin-bottom: 0;
    color: #7b7e80;
  }
`
export const SpecialOfferBold = styled.span`
  font-weight: 600;
`
export const SpecialOfferDescription = styled.span``
export const SpecialOfferFreeQuantity = styled.span`
  font-weight: 600;
  font-size: 16px !important;
  color: #282c2e !important;
`

export const CardSpecialOfferTitle = styled(Col)`
  margin-bottom: 25px;

  img {
    margin-right: 5px;
    width: 24px;
  }

  span {
    vertical-align: sub;
    font-size: 16px;
    font-weight: 700;
    ${(props) =>
      props.titlecolor &&
      `
      color: ${props.titlecolor};
    `}
  }
`
export default ForwardingPacks
