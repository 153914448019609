import { ContactContext } from '@/contexts/contactContext'
import { nextPage } from '@/reducers/workflow'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import firstStepIcon from 'src/images/steps/1-bg-blue.svg'
import checkFirstStepIcon from 'src/images/check/check-blue-bg-light-blue.svg'
import disabledSecondStepIcon from 'src/images/steps/2-bg-grey.svg'
import secondStepIcon from 'src/images/steps/2-bg-blue.svg'
import OnboardingStep from '@/app/LegalFlow/Onboarding/onboardingStep'

interface OnboardingProps {}

const Onboarding: React.FC<OnboardingProps> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { contact } = useContext(ContactContext)
  const firstname = contact.personalInformation.contactDetail.firstname

  const completed = false

  const options = [
    {
      id: '1',
      label: t('onboarding:firstStep'),
      withArrow: true,
      onClick: () => dispatch(nextPage()),
      icon: !completed ? firstStepIcon : checkFirstStepIcon,
      disabled: !completed
    },
    {
      id: '2',
      label: t('onboarding:secondStep'),
      withArrow: true,
      onClick: () => dispatch(nextPage()),
      icon: completed ? secondStepIcon : disabledSecondStepIcon,
      disabled: completed
    }
  ]

  return (
    <section className='section pt-[110px]'>
      <h1 className='title-1 mb-1.5'>
        {t('onboarding:title', {
          firstname: firstname
        })}
      </h1>
      <h3 className='body-1 mb-4'>{t('onboarding:subtitle')}</h3>
      <div className='flex flex-col gap-2'>
        {options.map((option) => (
          <OnboardingStep
            key={option.id}
            label={option.label}
            icon={option.icon}
            withArrow={option.withArrow}
            disabled={option.disabled}
            onClick={option.onClick}
          />
        ))}
      </div>
    </section>
  )
}

export default Onboarding
