import styled from 'styled-components'
import DashboardServices from './DashboardServices'
import FinalizeSubscription from './FinalizeSubscription'
import UserMails from './Usermails'
import { useContext } from 'react'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import LoyaltyAccess from '@/CustomerArea/dashboard/LoyaltyAccess'

const DashboardIndex = () => {
  const { domiciliation } = useContext(DomiciliationContext)
  const showUpsellCta =
    domiciliation?.upsellState === 'promoted' && domiciliation.canUpsell

  return (
    <DashboardWrapper className='section-dashboard container'>
      {showUpsellCta && <LoyaltyAccess />}
      {domiciliation && !domiciliation.finalized && <FinalizeSubscription />}
      {domiciliation?.hasSigned && <UserMails />}
      <DashboardServices />
    </DashboardWrapper>
  )
}

export const DashboardWrapper = styled.div`
  color: #171a1c;
  display: flex;
  flex-direction: column;
  gap: 48px;
`

export default DashboardIndex
