import { useEffect, useState } from 'react'
import { USER_MAILS_DOWNLOAD_ZIP } from '@/graphql/mutations/userMail'
import { Description, Title } from './style'
import { DatePicker, Col, Row, Button, Form } from 'antd'
import { useMutation } from '@apollo/client'
import moment from 'moment'
const { RangePicker } = DatePicker
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

interface IProps {
  setDrawerOpened: (arg: boolean) => void
  setDrawerError: (arg: string | undefined) => void
}

const DownloadZip = (props: IProps) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [datesIncomplete, setDatesIncomplete] = useState<boolean>(true)
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<string | undefined>(undefined)
  const [endDate, setEndDate] = useState<string | undefined>(undefined)

  const [userMailsDownloadZip] = useMutation(USER_MAILS_DOWNLOAD_ZIP)
  const onFinish = () => {
    setFormSubmitting(true)
    userMailsDownloadZip({
      variables: {
        startingDate: startDate,
        endingDate: endDate
      }
    }).then(async (res) => {
      if (res?.data?.error) {
        props.setDrawerOpened(true)
        props.setDrawerError(res.data.error.message)
      } else {
        props.setDrawerError(undefined)
        form.setFieldsValue({ datePicker: [null, null] })
        props.setDrawerOpened(true)
      }
      setFormSubmitting(false)
    })
  }
  const datePickerSelect = (dates) => {
    if (dates && dates[0] !== null) {
      const startDate = moment(dates[0].$d.toString()).format()
      setStartDate(startDate)
    }
    if (dates && dates[1] !== null) {
      const endDate = moment(dates[1].$d.toString()).format()
      setEndDate(endDate)
    }
  }

  useEffect(() => {
    if (endDate !== undefined && startDate !== undefined) {
      setDatesIncomplete(false)
    }
  }, [endDate, startDate])

  return (
    <Row gutter={[20, 0]} align={'middle'}>
      <Col lg={24} xl={12}>
        <Title>{t('userMails:downloadZip.actionTitle')}</Title>
        <Description>
          {t('userMails:downloadZip.actionDescription')}
        </Description>
      </Col>
      <Col lg={24} xl={12}>
        <Form onFinish={onFinish} form={form}>
          <Row>
            <Col span={24}>
              <Form.Item
                name='datePicker'
                rules={[
                  { required: true, message: 'Veuillez séléctionner vos dates' }
                ]}
                style={{ marginBottom: 10 }}
              >
                <RangePicker
                  format={'DD/MM/YYYY'}
                  style={{ width: '100%' }}
                  onChange={(dates) => datePickerSelect(dates)}
                  placement={'topRight'}
                  maxDate={dayjs()}
                  placeholder={[
                    t('userMails:filters.rangePicker.start'),
                    t('userMails:filters.rangePicker.end')
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button
                type='primary'
                htmlType='submit'
                block
                disabled={datesIncomplete || formSubmitting}
                className='downloadZip'
              >
                {t('userMails:downloadZip.submitText')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default DownloadZip
