import { Checkbox } from 'antd'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

interface CgvProps {
  checked: boolean
  setChecked: Dispatch<SetStateAction<boolean>>
}

const Cgv: React.FC<CgvProps> = ({ checked, setChecked }) => {
  const { t } = useTranslation()
  // const url = `https://sedomicilier.${data?.countryConfig?.countryCode?.toLowerCase()}/conditions-generales-service`
  return (
    <div className='mb-3'>
      <Checkbox checked={checked} onChange={() => setChecked(!checked)}>
        {t('payments:billing.validation.cgv')}
      </Checkbox>
      <a
        target={'_blank'}
        // href={url}
        className='body-2 !text-neutral-500 !underline p-3 pt-[0px] hover:!no-underline'
        rel='noreferrer'
      >
        {t('payments:billing.validation.readMention')}
      </a>
    </div>
  )
}

export default Cgv
