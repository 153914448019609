import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Tile } from './styles'
import { IProduct } from '@/interfaces/product'
import { Highlighted, AddProduct } from 'src/components/UIKit'
import ProductIcon from 'src/components/shared/ProductIcon'
import { CurrentCartContext } from 'src/contexts/currentCartContext'

interface IProps {
  legalFormality: IProduct
  setLegalFormalityTokenModal: (value: string) => void
  tileWidth: number
}

const Item = (props: IProps) => {
  const { t } = useTranslation()
  const { legalFormality, setLegalFormalityTokenModal, tileWidth } = props

  const myFormality = legalFormality.identificationToken
  const priceAmount = (legalFormality.price + legalFormality.legalFees) * 0.01
  const price = priceAmount + '€'
  const currentCart = useContext(CurrentCartContext)
  const purchase = currentCart.purchases.find(
    (e) => e.identificationToken === legalFormality.identificationToken
  )
  const purchaseDifferentPrice = purchase && purchase.amount !== priceAmount

  return (
    <Tile tileWidth={tileWidth}>
      <div className='tile-left'>
        <ProductIcon identificationToken={legalFormality.identificationToken} />
        <div>
          <h4>{t(`products:${myFormality}.title`)}</h4>
          <a
            className='informations'
            href='javascript:;'
            onClick={() => {
              setLegalFormalityTokenModal(legalFormality.identificationToken)
            }}
          >
            {t(`products:${myFormality}.learn_more`)}
          </a>
        </div>
      </div>
      {!purchaseDifferentPrice && (
        <div className='tile-right'>
          <Highlighted>{price}</Highlighted>
          <AddProduct product={legalFormality} />
        </div>
      )}
    </Tile>
  )
}

export default Item
