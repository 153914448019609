import { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import DashboardIndex from './dashboard/DashboardIndex'
import PaymentPage from './Payment'
import ServicesIndex from './services'
import ServicesRoomMeetingsBook from './services/roomMeetings/Book'
import TerminationSubscription from './Subscription/TerminationSubscription'
import ForwardingPacks from './ForwardingPacks'
import SettingsIndex from './settings/Index'
import Referrer from './referrer'
import Information from './domiciliationSubscription'
import LegalDocumentsList from './Kyc'
import AttestationsList from './documents'
import UserMailsIndex from './userMails'
import ContractSignature from './ContractSignature'
import Team from './Team'
import { AbilityContext } from 'src/contexts/abilityContext'
import { TagCommanderContextConsumer } from 'src/contexts/TagCommanderContext'
import ProtectedRoutes from 'src/components/ProtectedRoutes'
import ServicesHotlineForm from './services/hotlines/hotlineForm'
import WithTrackerComponent from './WithTrackerComponent'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'

const ROOT = '/'
const USER_MAILS_INDEX = '/courriers'
const USER_MAILS = '/courriers/:id?'
const PROFILE = '/mon-compte'
const PROFILE_LEGAL_FILES = '/pieces-justificatives'
const PROFILE_ATTESTATIONS = '/contrats'
const CART = '/cart'
const CART_PAYMENT = '/cart/payment'
const CART_PROCESSING = '/cart/processing'
const SUBSCRIPTION_TERMINATION = '/subscription/resiliation'
const SUBSCRIPTION_TERMINATION_ENDING_DATE =
  '/subscription/resiliation/date-de-fin'
const SUBSCRIPTION_TERMINATION_CONFIRMATION =
  '/subscription/resiliation/confirmation'
const SUBSCRIPTION_TERMINATION_SUMMARY = '/subscription/resiliation/summary'
const SUBSCRIPTION_TERMINATION_WITHDRAW = '/subscription/resiliation/withdraw'
const SUBSCRIPTION_PACKS = '/pack-de-reexpedition'
const TEAM = '/mon-equipe'
const REFERRER = '/parrainage'
const SERVICES_INDEX = '/services'
const SERVICES_HOTLINES_INDEX = '/services/permanence-telephonique'
const SERVICES_HOTLINES_FORM = '/services/permanence-telephonique/formulaire'
const SERVICES_ROOM_MEETINGS_INDEX = '/services/salles-de-reunion'
const SERVICES_ROOM_MEETINGS_BOOK = '/services/salles-de-reunion/reservation'
const CONTRACT_SIGNATURE = '/signature-contrat'
const LEGALS_TERMS = 'https://sedomicilier.fr/cgvu'
const FAQ = 'https://faq.sedomicilier.fr'
const WRITE_TO_US = 'https://sedomicilier.fr/contactez-nous'
const SETTINGS = '/configuration'

const Routing = () => {
  const ability = useContext(AbilityContext)
  dayjs.locale('fr')

  return (
    <TagCommanderContextConsumer>
      {(props) => (
        <Routes>
          <Route
            element={
              <ProtectedRoutes
                authorized={ability.can('customer_manage', 'Domiciliation')}
              />
            }
          >
            <Route
              path={ROOT}
              element={
                <WithTrackerComponent
                  component={<DashboardIndex />}
                  vars={props!.tcReloadOnly}
                />
              }
            />
            <Route
              path={SUBSCRIPTION_PACKS}
              element={
                <WithTrackerComponent
                  component={<ForwardingPacks />}
                  vars={props!.tcReloadOnly}
                />
              }
            />
            <Route
              path={`${SUBSCRIPTION_TERMINATION}/*`}
              element={
                <WithTrackerComponent
                  component={<TerminationSubscription />}
                  vars={props!.tcReloadOnly}
                />
              }
            />
            <Route
              path={SERVICES_HOTLINES_FORM}
              element={
                <WithTrackerComponent
                  component={<ServicesHotlineForm />}
                  vars={props!.tcReloadOnly}
                />
              }
            />
            <Route
              path={SERVICES_ROOM_MEETINGS_BOOK}
              element={
                <WithTrackerComponent
                  component={<ServicesRoomMeetingsBook />}
                  vars={props!.tcReloadOnly}
                />
              }
            />
          </Route>

          <Route
            element={
              <ProtectedRoutes
                authorized={ability.can('customer_read', 'UserMail')}
              />
            }
          >
            <Route
              path={USER_MAILS}
              element={
                <WithTrackerComponent
                  component={<UserMailsIndex />}
                  vars={props!.tcReloadOnly}
                />
              }
            />
          </Route>
          <Route
            path=''
            element={
              <ProtectedRoutes
                authorized={ability.can('customer_read', 'LegalDocument')}
              />
            }
          >
            <Route
              path={PROFILE}
              element={
                <WithTrackerComponent
                  component={<Information />}
                  vars={props!.tcReloadOnly}
                />
              }
            />
          </Route>
          <Route
            element={
              <ProtectedRoutes
                authorized={
                  ability.can('customer_read', 'LegalDocument') &&
                  ability.can('customer_read', 'Domiciliation')
                }
              />
            }
          >
            <Route
              path={PROFILE_LEGAL_FILES}
              element={
                <WithTrackerComponent
                  component={<LegalDocumentsList />}
                  vars={props!.tcReloadOnly}
                />
              }
            />
            <Route
              path={PROFILE_ATTESTATIONS}
              element={
                <WithTrackerComponent
                  component={<AttestationsList />}
                  vars={props!.tcReloadOnly}
                />
              }
            />
          </Route>
          <Route
            element={
              <ProtectedRoutes
                authorized={ability.can('customer_manage', 'Contact')}
              />
            }
          >
            <Route
              path={`${CART}/*`}
              element={
                <WithTrackerComponent
                  component={<PaymentPage />}
                  vars={props!.tcReloadOnly}
                />
              }
            />
            <Route
              path={`${SERVICES_INDEX}/*`}
              element={
                <WithTrackerComponent
                  component={<ServicesIndex />}
                  vars={props!.tcReloadOnly}
                />
              }
            />
          </Route>
          <Route
            element={
              <ProtectedRoutes
                authorized={ability.can('customer_read', 'User')}
              />
            }
          >
            <Route
              path={TEAM}
              element={
                <WithTrackerComponent
                  component={<Team />}
                  vars={props!.tcReloadOnly}
                />
              }
            />
          </Route>
          <Route
            element={
              <ProtectedRoutes
                authorized={
                  ability.can('customer_read', 'Referral') &&
                  ability.can('customer_read', 'Domiciliation')
                }
              />
            }
          >
            <Route
              path={REFERRER}
              element={
                <WithTrackerComponent
                  component={<Referrer />}
                  vars={props!.tcReloadOnly}
                />
              }
            />
          </Route>
          <Route
            element={
              <ProtectedRoutes
                authorized={ability.can('customer_sign', 'Domiciliation')}
              />
            }
          >
            <Route
              path={CONTRACT_SIGNATURE}
              element={
                <WithTrackerComponent
                  component={<ContractSignature />}
                  vars={props!.tcReloadOnly}
                />
              }
            />
          </Route>

          <Route
            path={`${SETTINGS}/*`}
            element={
              <WithTrackerComponent
                component={<SettingsIndex />}
                vars={props!.tcReloadOnly}
              />
            }
          />
        </Routes>
      )}
    </TagCommanderContextConsumer>
  )
}

export default {
  RoutingComponent: Routing,
  ROOT,
  USER_MAILS_INDEX,
  USER_MAILS,
  PROFILE,
  PROFILE_LEGAL_FILES,
  PROFILE_ATTESTATIONS,
  CART,
  CART_PAYMENT,
  CART_PROCESSING,
  SUBSCRIPTION_TERMINATION,
  SUBSCRIPTION_TERMINATION_ENDING_DATE,
  SUBSCRIPTION_TERMINATION_CONFIRMATION,
  SUBSCRIPTION_TERMINATION_SUMMARY,
  SUBSCRIPTION_TERMINATION_WITHDRAW,
  SUBSCRIPTION_PACKS,
  TEAM,
  REFERRER,
  SERVICES_INDEX,
  SERVICES_HOTLINES_INDEX,
  SERVICES_HOTLINES_FORM,
  SERVICES_ROOM_MEETINGS_INDEX,
  SERVICES_ROOM_MEETINGS_BOOK,
  CONTRACT_SIGNATURE,
  LEGALS_TERMS,
  FAQ,
  WRITE_TO_US,
  SETTINGS
}
