import { PAYMENT_INTENT_FRAGMENT, PURCHASE_FRAGMENT } from '@/graphql/Fragments'
import { gql } from '@apollo/client'

export const UPSELL_CART_FRAGMENT = gql`
  fragment UpsellCartFragment on Cart {
    amount
    amountVat
    amountWithVat
    createdAt
    firstRecurrentAmountByMonthWithReduction
    id
    paymentIntent {
      ...PaymentIntentFragment
    }
    paymentType
    purchases {
      ...PurchaseFragment
    }
    status
    totalReduction
  }
  ${PAYMENT_INTENT_FRAGMENT}
  ${PURCHASE_FRAGMENT}
`
