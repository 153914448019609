import React, { useState } from 'react'
import { Form, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'
import { nextPage } from '@/reducers/workflow'
import { useDispatch } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'
import {
  LEADER_CONTACT_DETAILS_QUERY,
  LeaderContactDetailsData
} from '@/graphql/queries/contactDetails'
import { UPSERT_LEGAL_REPRESENTATIVE_PHYSICAL } from '@/graphql/mutations/upsertLegalRepresentativePhysical'
import dayjs from 'dayjs'
import { BasicButton } from '@sedomicilier/material-sd'
import IdentityInformations from './identityInformations'
import PoliticallyExposed from '@/components/UIKit/components/PoliticallyExposed'

interface PhysicalProps {}

const Physical: React.FC<PhysicalProps> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [alpha2CountryCode, setAlpha2CountryCode] = useState<CountryCode>('FR')
  const [loading, setLoading] = useState<boolean>(false)

  const { data: contactData, loading: contactLoading } =
    useQuery<LeaderContactDetailsData>(LEADER_CONTACT_DETAILS_QUERY)

  const [upsertLegalRepresentative] = useMutation(
    UPSERT_LEGAL_REPRESENTATIVE_PHYSICAL
  )

  const leaderDetails = contactData?.contact.personalInformation.contactDetail
  const politicallyExposed =
    contactData?.contact.personalInformation.politicallyExposed

  const initialValues = {
    lastname: leaderDetails?.lastname,
    firstname: leaderDetails?.firstname,
    nationality: leaderDetails?.nationality,
    leaderPosition: leaderDetails?.position,
    phone: leaderDetails?.phoneNumber,
    email: leaderDetails?.email,
    personallyExposed: !!politicallyExposed?.personallyExposed,
    functionPerformed: politicallyExposed?.functionPerformed,
    cessationDate: politicallyExposed
      ? dayjs(politicallyExposed.cessationDate)
      : undefined,
    closeRelationExposed: !!politicallyExposed?.closeRelationExposed,
    closeRelationFunctionPerformed:
      politicallyExposed?.closeRelationFunctionPerformed,
    relationType: politicallyExposed?.closeRelationType
  }

  const onFinish = (values) => {
    if (!loading) {
      setLoading(true)
      const parsedPhoneNumber = values.phone
        ? parsePhoneNumber(values.phone, alpha2CountryCode)
        : null
      upsertLegalRepresentative({
        variables: {
          lastname: values.lastname,
          firstname: values.firstname,
          nationality: values.nationality,
          leaderPosition: values.leaderPosition,
          phoneNumber: parsedPhoneNumber?.number,
          email: values.email,
          personallyExposed: values.personallyExposed,
          functionPerformed: values.functionPerformed,
          cessationDate: values.cessationDate,
          closeRelationExposed: values.closeRelationExposed,
          closeRelationFunctionPerformed: values.closeRelationFunctionPerformed,
          closeRelationType: values.relationType
        }
      }).then(() => dispatch(nextPage()))
    }
  }
  return contactLoading ? (
    <div className='flex justify-center items-center'>
      <Spin size='large' />
    </div>
  ) : (
    <Form
      form={form}
      initialValues={initialValues}
      layout='vertical'
      requiredMark={false}
      onFinish={onFinish}
    >
      <div className='flex gap-2 flex-wrap mb-5'>
        <IdentityInformations
          form={form}
          alpha2CountryCode={alpha2CountryCode}
          setAlpha2CountryCode={setAlpha2CountryCode}
        />
        <PoliticallyExposed
          form={form}
          title={t('legalRepresentative:politicallyExposed.title')}
        />
        <BasicButton
          testid='legal-representative-next-button'
          label={t('common:continue')}
          bgColor='blue'
          loading={loading}
          className='w-full justify-center body-1 font-semibold'
        />
      </div>
    </Form>
  )
}

export default Physical
