import PaymentAmounts from '@/components/payment/billing/paymentAmounts'
import PaymentTitle from '@/components/payment/billing/paymentTitle'
import PaymentBlock from '@/components/payment/paymentBlock'
import PromoCode from '@/components/payment/summary/promoCode'
import SumUp from '@/components/payment/summary/sumUp'
import { SumUpPurchases } from '@/app/Loyalty/Payment/summary'
import { UPSELL_CART_FRAGMENT } from '@/graphql/Fragments/cart'
import {
  APPLY_PROMOTION_MUTATION,
  REMOVE_PROMOTION_MUTATION
} from '@/graphql/mutations/promoCode'
import { useDispatch } from '@/hooks/redux'
import { IProduct } from '@/interfaces/product'
import { useMutation } from '@apollo/client'
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit'
import { BasicButton } from '@sedomicilier/material-sd'
import { Divider } from 'antd'
import { useTranslation } from 'react-i18next'

interface OrderSummaryProps {
  purchases: SumUpPurchases
  promoPurchase?: IProduct
  nextPage: ActionCreatorWithOptionalPayload<string | undefined, string>
  htAmount: number
  deductibleVAT: number
  ttcAmount: number
  applyBillingCycleLoading: boolean
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  htAmount,
  deductibleVAT,
  ttcAmount,
  purchases,
  promoPurchase,
  nextPage,
  applyBillingCycleLoading
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [applyPromoCode, { data, loading: loadingApply }] = useMutation(
    APPLY_PROMOTION_MUTATION,
    {
      update: (cache, { data }) => {
        if (data?.applyPromotion?.currentCart) {
          const currentCart = data.applyPromotion.currentCart
          cache.writeFragment({
            id: cache.identify(currentCart),
            fragment: UPSELL_CART_FRAGMENT,
            fragmentName: 'UpsellCartFragment',
            data: currentCart
          })
        }
      }
    }
  )

  const [removePromoCode, { loading: loadingRemove }] = useMutation(
    REMOVE_PROMOTION_MUTATION,
    {
      update: (cache, { data }) => {
        if (data?.removePromotion?.currentCart) {
          const currentCart = data.removePromotion.currentCart
          cache.writeFragment({
            id: cache.identify(currentCart),
            fragment: UPSELL_CART_FRAGMENT,
            fragmentName: 'UpsellCartFragment',
            data: currentCart
          })
        }
      }
    }
  )
  return (
    <PaymentBlock>
      <PaymentTitle title={t('payments:summary.order.title')} />
      <SumUp total={htAmount} purchases={purchases} />
      <PromoCode
        promoPurchase={promoPurchase}
        promoCodeData={data}
        applyPromoCode={applyPromoCode}
        loadingApply={loadingApply}
        removePromoCode={removePromoCode}
        loadingRemove={loadingRemove}
      />
      <Divider />
      <PaymentAmounts
        htAmount={htAmount}
        deductibleVAT={deductibleVAT}
        ttcAmount={ttcAmount}
      />
      <BasicButton
        testid='frequency-next-button'
        label={t('common:validate')}
        bgColor='blue'
        className='font-semibold w-full justify-center mt-4'
        onClick={() => dispatch(nextPage())}
        loading={applyBillingCycleLoading || loadingApply || loadingRemove}
        withArrow
      />
    </PaymentBlock>
  )
}

export default OrderSummary
