import Failure from '@/components/payment/processing/failure'
import Success from '@/components/payment/processing/success'
import { useStripe } from '@stripe/react-stripe-js'
import { Spin } from 'antd'
import { useEffect, useState } from 'react'

interface UpsellPaymentProcessingProps {}

const UpsellPaymentProcessing: React.FC<UpsellPaymentProcessingProps> = () => {
  const stripe = useStripe()
  const [status, setStatus] = useState<string | undefined>()

  useEffect(() => {
    if (!stripe) {
      return
    }

    const paymentClientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )

    if (paymentClientSecret) {
      stripe
        .retrievePaymentIntent(paymentClientSecret)
        .then(({ paymentIntent }) => {
          setStatus(paymentIntent?.status || 'failure')
        })
    }

    return
  }, [stripe])

  if (status) {
    const reduxSession = sessionStorage.getItem('persist:root')
    if (reduxSession) {
      const json = JSON.parse(reduxSession)

      delete json.loyaltyFlow
      sessionStorage.setItem('persist:root', JSON.stringify(json))
    }
  }

  return !status ? (
    <div className='absolute top-[50%] left-[50%]'>
      <Spin size='large' />
    </div>
  ) : status === 'succeeded' || status === 'processing' ? (
    <Success />
  ) : (
    <Failure />
  )
}

export default UpsellPaymentProcessing
