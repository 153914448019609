import { useState, useEffect } from 'react'
import { BackButton } from '@sedomicilier/material-sd'
import { useDispatch } from '@/hooks/redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { ModuleType } from '@/workflows/modules'
import { useTranslation } from 'react-i18next'
import { Steps } from 'antd'
import constants from 'src/constants'
import { useWindowDimensions } from '@/components/shared/useWindowDimensions'
import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload
} from '@reduxjs/toolkit'

interface NavigationProps {
  steps: ModuleType[]
  translation: string
  flow: string[]
  previousPage: ActionCreatorWithoutPayload<string>
  setPath: ActionCreatorWithPayload<string, string>
}

const Navigation: React.FC<NavigationProps> = ({
  steps,
  translation,
  flow,
  previousPage,
  setPath
}) => {
  const { t } = useTranslation('navigation')
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { width } = useWindowDimensions()

  const pathname = location.pathname

  const desktop =
    width >= parseInt(constants.breakpoints['xlarge-breakpoint-i'], 10)

  const [currentStep, setCurrentStep] = useState(
    steps.findIndex((item: ModuleType) => item.path === pathname)
  )

  useEffect(() => {
    setCurrentStep(
      steps.findIndex((item: ModuleType) => item.path === pathname)
    )
  }, [pathname])

  const onChange = (value: number) => {
    const newPath = steps[value].path
    if (flow.includes(newPath)) dispatch(setPath(newPath))
  }

  const getStatus = (index: number) => {
    if (index === currentStep) {
      return 'process'
    } else if (flow.includes(steps[index].path)) {
      return 'finish'
    } else {
      return 'wait'
    }
  }

  const goBack = () => {
    if (pathname === flow[0]) navigate('/')
    else dispatch(previousPage())
  }

  const className = desktop
    ? 'flex items-start flex-col m-2 fixed'
    : 'flex items-start flex-col m-2'

  useEffect(() => {
    const processStep = document.querySelector('.ant-steps-item-process')
    const finishSteps = document.querySelectorAll('.ant-steps-item-finish')

    if (processStep) {
      processStep
        .querySelector('.ant-steps-item-title')!
        .classList.add('!text-interaction', 'font-semibold')
      finishSteps.forEach((item) =>
        item
          .querySelector('.ant-steps-item-title')
          ?.classList.remove('!text-interaction', 'font-semibold')
      )
      return
    }
    return
  }, [currentStep])

  if (pathname === '/legal-flow/onboarding') {
    return null
  }

  return (
    <div className={className}>
      {/* TODO: REFAC SOON it's hot fix */}
      {pathname !== '/legal-flow/forwarding-address' && (
        <BackButton label={t('common:back')} size='large' onClick={goBack} />
      )}
      <Steps
        progressDot
        className='mt-5 h-fit w-max [&_.ant-steps-item-active]:!text-interaction'
        current={currentStep}
        onChange={onChange}
        direction='vertical'
        items={steps.map((item: ModuleType, index: number) => ({
          title: t(`${translation}.steps.${item.name}`),
          status: getStatus(index),
          disabled: !flow.includes(item.path)
        }))}
      />
    </div>
  )
}

export default Navigation
