import profile from 'src/images/profile/profile-grey.svg'
import DocumentRow from '../documentRow'
import { useTranslation } from 'react-i18next'
import {
  ProcedureCardWrapper,
  TopPart,
  ProcedureCardIcon,
  SectionTitle,
  DynamicName,
  Documents
} from './styles'
import { IContactDetail } from '@/interfaces/contactDetail'
import { DocumentsType } from '@/CustomerArea/Kyc/Identity'
import { Dispatch, SetStateAction } from 'react'

interface ProcedureCardProps {
  targetType: string
  targetId: string
  fullName: string
  documents: DocumentsType[]
  setDrawerCategory: Dispatch<SetStateAction<string | null>>
  setDrawerAllowDocumentTypes: ([string]) => void
  idx: number
  moralShareholderName: string
  contactDetail: IContactDetail
}

const ProcedureCard = ({
  targetId,
  targetType,
  fullName,
  documents,
  setDrawerCategory,
  setDrawerAllowDocumentTypes,
  idx,
  moralShareholderName,
  contactDetail
}: ProcedureCardProps) => {
  const { t } = useTranslation()

  const shareholder =
    targetType === 'Shareholder_Physical' || targetType === 'Shareholder_Moral'

  const getName = () => {
    if (shareholder) {
      if (moralShareholderName) {
        return <DynamicName>{moralShareholderName}</DynamicName>
      }
      if (contactDetail.firstname && contactDetail.lastname) {
        return (
          <DynamicName>
            {contactDetail.firstname} {contactDetail.lastname}
          </DynamicName>
        )
      }
      return <DynamicName>{idx}</DynamicName>
    }
    return <DynamicName>{fullName}</DynamicName>
  }
  return (
    <ProcedureCardWrapper>
      <TopPart>
        <ProcedureCardIcon src={profile} alt='profile' />
        <SectionTitle>
          {t(`procedure:sectionTitle.${targetType}`)} {getName()}
        </SectionTitle>
      </TopPart>
      <Documents>
        {documents.map((document, i) => {
          return (
            <DocumentRow
              key={i}
              document={document}
              setDrawerCategory={setDrawerCategory}
              setDrawerAllowDocumentTypes={setDrawerAllowDocumentTypes}
              targetId={targetId}
              targetName={fullName}
            />
          )
        })}
      </Documents>
    </ProcedureCardWrapper>
  )
}

export default ProcedureCard
