import tadaIcon from '@/images/dashboard/tada-icon.svg'
import { BasicButton } from '@sedomicilier/material-sd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface LoyaltyAccessProps {}

const LoyaltyAccess: React.FC<LoyaltyAccessProps> = () => {
  const { t } = useTranslation('dashboard')
  return (
    <div className='bg-primary-100 rounded-medium flex justify-between items-center p-2 gap-2 mobile:flex-col mobile:!gap-1'>
      <img src={tadaIcon} alt='tada icon' />
      <div className='flex-1'>
        <p className='body-2 font-semibold'>{t('loyalty.goodNews')}</p>
        <p className='text-6'>{t('loyalty.offer')}</p>
      </div>
      <Link to='/loyalty/offer'>
        <BasicButton
          label={t('loyalty.cta')}
          bgColor='transparent'
          className='!bg-warning-500 !text-neutral-0 !border-none font-semibold'
        />
      </Link>
    </div>
  )
}

export default LoyaltyAccess
