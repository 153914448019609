import SummarySection from '@/app/LegalFlow/Summary/summarySections/summarySection'
import { SUMMARY_PAGE_QUERY } from '@/graphql/queries/pages/summary'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

interface SummarySectionsProps {}

const SummarySections: React.FC<SummarySectionsProps> = () => {
  const { t } = useTranslation()
  const { data } = useQuery(SUMMARY_PAGE_QUERY)

  const forwardingAddress = data?.domiciliation.forwardingAddress
  const contactDetails = data?.contact.personalInformation.contactDetail
  const society = data?.contact.society

  const shareholders = data?.domiciliation.kycLogicRules.filter(
    (item) =>
      item.targetType === 'Shareholder_Physical' ||
      item.targetType === 'Shareholder_Moral'
  )

  const shareholdersName = shareholders?.map((shareholder) =>
    shareholder.targetType === 'Shareholder_Physical'
      ? `${shareholder.contactDetail.firstname} ${shareholder.contactDetail.lastname}`
      : shareholder.moralShareholderName
  )

  const sections = {
    mails: [
      {
        label: t('summary:mails.address'),
        data: forwardingAddress?.fullAddress
      }
    ],
    legalRepresentative: [
      {
        label: t('summary:legalRepresentative.names'),
        data: `${contactDetails?.lastname} ${contactDetails?.firstname}`
      },
      {
        label: t('summary:legalRepresentative.nationality'),
        data: contactDetails?.nationalityName
      },
      {
        label: t('summary:legalRepresentative.role'),
        data: contactDetails?.position
      },
      {
        label: t('summary:legalRepresentative.email'),
        data: contactDetails?.email
      },
      {
        label: t('summary:legalRepresentative.phone'),
        data: contactDetails?.phoneNumber
      }
    ],
    company: [
      {
        label: t('summary:company.name'),
        data: society?.name
      },
      {
        label: t('summary:company.brandName'),
        data: society?.brandName
      },
      {
        label: t('summary:company.activitySector'),
        data: society?.apeCategory
      },
      {
        label: t('summary:company.activity'),
        data: society?.apeActivity
      },
      {
        label: t('summary:company.beneficialOwner'),
        data: shareholdersName,
        shareholder: true
      }
    ],
    documentLocation: [
      {
        label: t('summary:accountant.location'),
        data: t(
          `summary:accountant.${data?.domiciliation?.documentHost?.location}`
        )
      }
    ]
  }

  return (
    <div className='p-3 border border-neutral-200 rounded-medium'>
      <SummarySection
        modifyPath='forwarding-address'
        icon={
          <span className='ico-letter text-l bg-neutral-50 p-1.5 rounded-medium' />
        }
        title={t('summary:mails.title')}
        bottomSeparator={true}
      >
        {sections.mails.map((item, index) => (
          <div key={index} className='body-1'>
            <p className='text-neutral-500'>{item.label}</p>
            {item.data}
          </div>
        ))}
      </SummarySection>

      <SummarySection
        modifyPath='legal-representative'
        icon={
          <span className='ico-d-silhouette text-l bg-neutral-50 p-1.5 rounded-medium' />
        }
        bottomSeparator={true}
        title={t('summary:legalRepresentative.title')}
      >
        {sections.legalRepresentative.map((item, index) => (
          <div key={index} className='flex gap-1 body-1'>
            <p className='text-neutral-500'>{item.label}</p>
            {item.data}
          </div>
        ))}
      </SummarySection>

      <SummarySection
        modifyPath='company-activity'
        icon={
          <span className='ico-d-building text-l bg-neutral-50 p-1.5 rounded-medium' />
        }
        bottomSeparator={true}
        title={t('summary:company.title')}
      >
        {sections.company.map((item, index) =>
          !item.shareholder ? (
            <div key={index} className='flex gap-1 body-1'>
              <p className='text-neutral-500'>{item.label}</p>
              {item.data}
            </div>
          ) : (
            <div key={index}>
              <p className='text-neutral-500'>{item.label}</p>
              <ul className='list-inside list-disc pl-1'>
                {shareholdersName?.map((shareholderName, index) => (
                  <li key={index}>
                    {t('summary:company.coLeader', {
                      index: index + 1
                    })}
                    {shareholderName}
                  </li>
                ))}
              </ul>
            </div>
          )
        )}
      </SummarySection>

      <SummarySection
        modifyPath='accounting'
        icon={
          <span className='ico-d-calculator text-l bg-neutral-50 p-1.5 rounded-medium' />
        }
        bottomSeparator={false}
        title={t('summary:accountant.title')}
      >
        {sections.documentLocation.map((item, index) => (
          <div key={index} className='flex gap-1 body-1'>
            <p className='text-neutral-500'>{item.label}</p>
            {item.data}
          </div>
        ))}
      </SummarySection>
    </div>
  )
}

export default SummarySections
