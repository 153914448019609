import gql from 'graphql-tag'
import { ADDRESS_DETAIL_FRAGMENT } from './addressDetail'
import { CONTACT_DETAIL_FRAGMENT } from './contactDetail'
import { POLITICALLY_EXPOSED_FRAGMENT } from './politicallyExposed'

export const PERSONAL_INFORMATION_FRAGMENT = gql`
  fragment PersonalInformationFragment on PersonalInformation {
    id
    legalType
    name
    legalStatus
    addressDetail {
      ...AddressDetailFragment
    }
    contactDetail {
      ...ContactDetailFragment
    }
    politicallyExposed {
      ...PoliticallyExposedFragment
    }
  }
  ${ADDRESS_DETAIL_FRAGMENT}
  ${CONTACT_DETAIL_FRAGMENT}
  ${POLITICALLY_EXPOSED_FRAGMENT}
`
