const files = require.context('src/locales/zh', true, /\.json$/)
const zh = {}

files.keys().forEach((filename) => {
  const fileContent = files(filename)
  const pathSegments = filename.replace(/^\.\//, '').split('/')
  let target = zh

  // Parcourir chaque segment du chemin sauf le dernier (nom du fichier)
  for (let i = 0; i < pathSegments.length - 1; i++) {
    const segment = pathSegments[i]
    target[segment] = target[segment] || {}
    target = target[segment]
  }

  // Récupérer le nom du fichier
  const fileName = pathSegments[pathSegments.length - 1].replace(/\.json$/, '')
  target[fileName] = fileContent
})

export default zh
