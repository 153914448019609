export const zendeskSettings = ({ domiciliation, currentUser, contact }) => {
  if (domiciliation?.affiliated) {
    return {
      authenticate: {
        chat: {
          jwtFn: (callback: any) => {
            callback(currentUser.zendeskChatToken)
          }
        }
      },
      launcher: {
        chatLabel: { '*': 'Aide' },
        badge: {
          label: { '*': 'Nous contacter' },
          image: 'https://via.placeholder.com/80',
          layout: 'image_right'
        }
      },
      concierge: {
        avatarPath: 'https://via.placeholder.com/80',
        name: 'Jane Doe',
        title: { '*': 'Aide Bot' }
      },
      chat: {
        profileCard: {
          avatar: false,
          rating: true,
          title: true
        }
      },
      talk: { suppress: true },
      contactForm: {
        ticketForms: [{ id: '13303364112530' }, { id: '14460732798994' }],
        selectTicketForm: { '*': ' ' }
      }
    }
  } else {
    return {
      authenticate: {
        chat: {
          jwtFn: (callback: any) => {
            callback(currentUser.zendeskChatToken)
          }
        }
      },
      launcher: {
        chatLabel: { '*': 'Discuter avec un agent' },
        label: { '*': 'Nous contacter' }
      },
      talk: { suppress: true },
      contactForm: {
        ticketForms: [{ id: '13303364112530' }, { id: '14460732798994' }],
        selectTicketForm: { '*': ' ' },
        fields: [
          {
            id: 'name',
            prefill: {
              // eslint-disable-next-line max-len
              '*': `${contact?.personalInformation?.contactDetail?.firstname} ${contact?.personalInformation?.contactDetail?.lastname}`
            }
          },
          { id: 'email', prefill: { '*': contact?.email } },
          { id: 'key:13303331298194', prefill: { '*': contact?.phoneNumber } },
          { id: 'key:360011120119', prefill: { '*': contact?.society?.name } }
        ]
      }
    }
  }
}

export const zendeskKey = ({ domiciliation }) => {
  const whitebrandKey = process.env.REACT_APP_HELLOPRIMO_ZENDESK_KEY!
  const sedomicilierKey = process.env.REACT_APP_SEDOMICILIER_ZENDESK_KEY!
  return domiciliation?.affiliated ? whitebrandKey : sedomicilierKey
}
