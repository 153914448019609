import { ICart } from '@/interfaces/cart'
import { IProduct } from '@/interfaces/product'
import { FetchResult } from '@apollo/client'
import { BasicButton } from '@sedomicilier/material-sd'
import { Form, FormInstance, Input } from 'antd'
import { useTranslation } from 'react-i18next'

interface PromoCodeProps {
  promoPurchase?: IProduct
  promoCodeData?: { error: { message: string } }
  applyPromoCode: (
    options
  ) => Promise<FetchResult<{ applyPromotion: { currentCart: ICart } }>>
  removePromoCode: () => void
  loadingApply: boolean
  loadingRemove: boolean
}

const PromoCode: React.FC<PromoCodeProps> = ({
  promoPurchase,
  promoCodeData,
  applyPromoCode,
  removePromoCode,
  loadingApply,
  loadingRemove
}) => {
  const { t } = useTranslation()
  const [form]: [FormInstance] = Form.useForm()

  const onFinish = (values) => {
    if (promoPurchase) {
      removePromoCode()
    } else {
      applyPromoCode({
        variables: {
          token: values.promoCode
        }
      }).then(() => {
        form.resetFields()
      })
    }
  }

  return (
    <>
      <Form
        form={form}
        name='promo-code-form'
        className='flex items-end justify-between mt-3 gap-2'
        onFinish={onFinish}
        requiredMark={false}
        layout='vertical'
      >
        <Form.Item
          name='promoCode'
          label={t('payments:summary.order.promoCode.title')}
          className='flex-1 mb-[0px]'
        >
          <Input
            data-testid='promo-code-input'
            className='h-5'
            placeholder={t('payments:summary.order.code')}
            disabled={!!promoPurchase}
          />
        </Form.Item>
        {promoPurchase ? (
          <BasicButton
            testid='promo-code-remove-button'
            loading={loadingRemove}
            label={t('common:delete')}
            bgColor='transparent'
            className='h-5 body-2 text-neutral-0 font-semibold'
          />
        ) : (
          <BasicButton
            testid='promo-code-apply-button'
            loading={loadingApply}
            label={t('common:apply')}
            bgColor='blue'
            className='h-5 body-2 text-neutral-0 font-semibold'
          />
        )}
      </Form>
      {promoCodeData?.error?.message && (
        <div className='text-error-500 font-semibold'>
          {t(
            `payments:summary.order.promoCode.errors.${promoCodeData?.error?.message}`
          )}
        </div>
      )}
    </>
  )
}

export default PromoCode
