import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/store'
import { generateFlow } from '@/workflows/utils'
import { entryModule, loyaltyModules } from '@/workflows/loyaltyModules'

export interface WorkflowState {
  path: string
  flow: string[]
}

const initialState: WorkflowState = {
  path: '/loyalty/offer',
  flow: [entryModule.path]
}

export const loyaltyFlowSlice = createSlice({
  name: 'loyaltyFlow',
  initialState,
  reducers: {
    nextPage: (state, action: PayloadAction<string | undefined>) => {
      const currentModule = loyaltyModules.find(
        (mod) => mod.path === state.path
      )!
      const nextModule = currentModule.nextPage(action?.payload)

      if (nextModule) {
        state.flow = generateFlow(state.flow, state.path, nextModule.path)
        state.path = nextModule.path
      }
    },
    previousPage: (state) => {
      const previousPathIndex = state.flow.indexOf(state.path) - 1
      if (previousPathIndex >= 0) state.path = state.flow.at(previousPathIndex)!
    },
    setPath: (state, action: PayloadAction<string>) => {
      state.path = action.payload
    }
  }
})

export const { nextPage, previousPage, setPath } = loyaltyFlowSlice.actions
export const selectLoyaltyFlow = (state: RootState) => state.loyaltyFlow

export default loyaltyFlowSlice.reducer
