import { useContext } from 'react'
import { ContactContext } from 'src/contexts/contactContext'
import session from 'src/session'
import { Button, Divider, Dropdown, Space, Tooltip, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { MenuProps } from 'antd/lib'
import { useMutation, useQuery } from '@apollo/client'
import { CONTACTS_QUERY } from '@/graphql/queries/contact'
import { CONTACT_SELECT_MUTATION } from '@/graphql/mutations/contact'

const DropdownContact = () => {
  const { t } = useTranslation()
  const { contact } = useContext(ContactContext)
  const countryCode = contact?.countryConfig?.countryCode || 'FR'
  const { data, loading, error } = useQuery(CONTACTS_QUERY)
  const [selectContact] = useMutation(CONTACT_SELECT_MUTATION)

  const societyName = contact.society.name
    ? `${contact.society.name} ${contact.domiciliationStatus ? t(`nav:accounts.${contact.domiciliationStatus}`) : ''}`
    : t('nav:myAccount')
  const isCanceled =
    contact.domiciliationStatus &&
    (contact.domiciliationStatus === 'cancelation_in_progress' ||
      contact.domiciliationStatus === 'canceled')

  const handleDisconnect = () => {
    session.clearSession()
    const protocol =
      process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http' : 'https'
    const baseUrl = `${protocol}://${process.env['REACT_APP_ROOT_PATH_' + countryCode]}`
    window.location.href = `${baseUrl}/sessions/deconnexion`
  }

  const switchContact = async (contactId: number) => {
    try {
      const { data } = await selectContact({
        variables: { contactId: contactId }
      })
      if (data.error) {
        notification.error({
          message: t('common:error'),
          description: data.error.message,
          duration: 5
        })
      } else if (data.contactSelect?.token) {
        sessionStorage.removeItem('persist:root')
        session.refreshSession(data.contactSelect.token)
        window.location.href = '/'
      }
    } catch (error) {
      notification.error({
        message: t('common:error'),
        description: t('common:error'),
        duration: 5
      })
    }
  }

  const items = data?.contacts
    ?.filter((listedContact) => listedContact.id !== contact.id)
    .map((listedContact) => {
      return {
        key: listedContact?.society.id ?? 0,
        label: `${listedContact?.society.name ?? t('nav:myAccount')} ${
          listedContact.domiciliationStatus
            ? t(`nav:accounts.${listedContact.domiciliationStatus}`)
            : ''
        }`,
        onClick: () => switchContact(listedContact.id)
      }
    }) as MenuProps['items']

  const baseStyle =
    '!text-neutral-700 flex justify-between items-center w-fit mobile:overflow-hidden [&>span]:mobile:overflow-hidden [&>span]:mobile:text-ellipsis'
  const activeStyle = `${baseStyle} !bg-neutral-200`
  const dangerStyle = `${baseStyle} !bg-error-300`

  const listStyle = isCanceled
    ? '[&_.ant-dropdown-menu-title-content]:text-error-600'
    : ''

  if (error) {
    return <span>{`${t('common:error')}! ${error.message}`}</span>
  } else {
    return (
      <Dropdown
        menu={{ items }}
        dropdownRender={(menu) => (
          <div
            className={`bg-neutral-0 rounded-small [&_.ant-dropdown-menu]:bg-neutral-0 [&_.ant-dropdown-menu]:px-[0px] [&_.ant-dropdown-menu]:shadow-none [&_.ant-dropdown-menu-item-active]:hover:bg-neutral-100 [&_.ant-dropdown-menu-item]:!rounded-[0px] ${listStyle}`}
          >
            {menu}
            <Divider style={{ margin: 0 }} />
            <Space className='p-1 w-full [&_.ant-space-item]:w-full'>
              <Button
                className='primary-button w-full'
                type='primary'
                onClick={handleDisconnect}
              >
                {t('common:disconnect')}
              </Button>
            </Space>
          </div>
        )}
      >
        <Button
          className={isCanceled ? dangerStyle : activeStyle}
          loading={loading}
        >
          <Tooltip title={societyName} placement='right'>
            {societyName}
          </Tooltip>
          <span className='ico-caret-down text-5' />
        </Button>
      </Dropdown>
    )
  }
}

export default DropdownContact
