import { ReactNode, useEffect, useState } from 'react'
import PaymentSummaryComponent from '@/components/payment/summary'
import {
  UPSELL_ALL_CARTS,
  UPSELL_CURRENT_CART
} from '@/graphql/queries/upsellCarts'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { nextPage } from '@/reducers/loyaltyFlow'
import { Spin } from 'antd'
import { Frequency, ICart } from '@/interfaces/cart'
import { UPSELL_APPLY_BILLING_CYCLE } from '@/graphql/mutations/upsellApplyBillingCycle'
import { UPSELL_CART_FRAGMENT } from '@/graphql/Fragments/cart'

export type SumUpPurchases = {
  title: string
  pricing?: ReactNode
  subtitle?: string
  className?: string
  testId?: string
}[]

export interface UpsellInitialValues {
  paymentFrequency: Frequency
}

const PaymentSummary: React.FC = () => {
  const { t } = useTranslation()

  const { data, loading } = useQuery(UPSELL_CURRENT_CART)
  const { data: allCartsData, loading: allCartsLoading } = useQuery(
    UPSELL_ALL_CARTS,
    { fetchPolicy: 'no-cache' }
  )

  const [upsellApplyBillingCycle, { loading: applyBillingCycleLoading }] =
    useMutation(UPSELL_APPLY_BILLING_CYCLE, {
      refetchQueries: [UPSELL_ALL_CARTS],
      update: (cache, { data }) => {
        if (data?.upsellApplyBillingCycle?.currentCart) {
          const currentCart = data.upsellApplyBillingCycle.currentCart
          cache.writeFragment({
            id: cache.identify(currentCart),
            fragment: UPSELL_CART_FRAGMENT,
            fragmentName: 'UpsellCartFragment',
            data: currentCart
          })
        }
      }
    })

  const currentCart: ICart = data?.upsellCurrentCart

  const [frequency, setFrequency] = useState<Frequency | undefined>()

  const domiciliationPrice = currentCart?.purchases.find(
    (purchase) => purchase.identificationToken === 'domiciliation'
  )?.amount

  const currenPaymentType = currentCart?.paymentType

  const mailPurchaseAmount = currentCart?.purchases.find(
    (purchase) => purchase.category === 'mail'
  )?.amount

  const promoPurchase = currentCart?.purchases.find(
    (purchase) => purchase.category === 'promotion'
  )

  const totalProrateDeduction = currentCart?.purchases.reduce(
    (total, purchase) => {
      if (
        purchase.category === 'prorate_mail_option' ||
        purchase.category === 'prorate_domiciliation'
      ) {
        return total + purchase.amount
      }
      return total
    },
    0
  )

  const purchases = [
    {
      testId: 'domiciliation',
      title: t('payments:summary.order.subscription.domiciliation'),
      pricing: (
        <span>
          {t('common:pricing.€ByPeriodHT', {
            price: domiciliationPrice,
            period: t('common:paymentType.' + currenPaymentType)
          })}
        </span>
      )
    },
    {
      testId: 'mail-purchase',
      title: t('payments:summary.order.subscription.mail'),
      pricing: (
        <span>
          {mailPurchaseAmount === 0
            ? t('common:free')
            : t('common:pricing.€ByPeriodHT', {
                price: mailPurchaseAmount,
                period: t('common:paymentType.' + currenPaymentType)
              })}
        </span>
      )
    },
    !!promoPurchase && {
      testId: 'promo-code',
      title: t('payments:summary.order.subscription.promoCode'),
      className: 'text-yellow-500',
      pricing: (
        <span>
          {t('common:pricing.€', {
            price: promoPurchase?.amount
              ?.toFixed(2)
              .toString()
              .replace('.', ',')
          })}
        </span>
      )
    },
    {
      testId: 'deduction',
      title: t('payments:summary.order.subscription.deduction'),
      className: 'text-green-500',
      pricing: (
        <span>
          {t('common:pricing.€', {
            price: totalProrateDeduction
              ?.toFixed(2)
              .toString()
              .replace('.', ',')
          })}
        </span>
      )
    }
  ].filter(Boolean) as SumUpPurchases

  useEffect(() => {
    if (frequency && !applyBillingCycleLoading)
      upsellApplyBillingCycle({
        variables: {
          billingCycle: frequency
        }
      })
    return
  }, [frequency])

  return loading || allCartsLoading ? (
    <div className='absolute top-[50%] left-[50%]'>
      <Spin size='large' />
    </div>
  ) : (
    <div className='w-full'>
      <PaymentSummaryComponent
        setFrequency={setFrequency}
        frequency={currentCart?.paymentType}
        purchases={purchases}
        promoPurchase={promoPurchase}
        nextPage={nextPage}
        allCarts={allCartsData?.upsellAllCarts}
        currentCart={currentCart}
        applyBillingCycleLoading={applyBillingCycleLoading}
      />
    </div>
  )
}

export default PaymentSummary
