import AddCard from '@/components/payment/billing/addCard'
import PaymentTitle from '@/components/payment/billing/paymentTitle'
import RegisteredCards from '@/components/payment/billing/registeredCards'
import PaymentBlock from '@/components/payment/paymentBlock'
import { BasicLink } from '@sedomicilier/material-sd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IPaymentSource } from '@/interfaces/paymentSource'

interface CardDetailsProps {
  cards: IPaymentSource[]
}

const CardDetails: React.FC<CardDetailsProps> = ({ cards }) => {
  const { t } = useTranslation()
  const [addCard, setAddCard] = useState(false)

  return (
    <PaymentBlock>
      <PaymentTitle
        title={t('payments:billing.cardDetails.title')}
        subtitle={t('payments:billing.cardDetails.subtitle')}
        icon={<span className='ico-lock text-neutral-600' />}
      />
      {!addCard && (
        <>
          {cards.length > 0 && <RegisteredCards cards={cards} />}
          <button
            onClick={() => setAddCard(true)}
            className='w-full py-1 px-2 rounded-small flex items-center justify-center gap-2 bg-primary-500 text-5 text-neutral-0 hover:bg-primary-600 hover:disabled:bg-primary-100 disabled:bg-primary-100'
          >
            <span className='ico-plus' />
            {t('payments:billing.cardDetails.otherPaymentMethod')}
          </button>
        </>
      )}
      {addCard && (
        <>
          <BasicLink
            color='blue'
            label={t('payments:billing.cardDetails.back')}
            onClick={() => setAddCard(false)}
            className='!text-interaction !underline hover:!no-underline'
            withArrow
          />
          <AddCard />
        </>
      )}
    </PaymentBlock>
  )
}

export default CardDetails
