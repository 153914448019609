import LanguageSelect from '@/components/languageSelect'

const Footer: React.FC = () => {
  return (
    <footer className='min-h-[80px] bg-neutral-150 flex items-center justify-start tablet:pl-5'>
      <LanguageSelect />
    </footer>
  )
}

export default Footer
