import { useContext, useState } from 'react'
import {
  LegalflowSection,
  NoticeInformation,
  BasicButton
} from '@sedomicilier/material-sd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { nextPage } from '@/reducers/workflow'
import { Form } from 'antd'
import { useMutation } from '@apollo/client'
import { ContactContext } from '@/contexts/contactContext'
import { DomiciliationContext } from '@/contexts/DomiciliationContext'
import FormContent from './formContent'
import CSIcon from '@/images/legalFlow/circled_star.svg'
import { DOMICILIATION_FORWARDING_ADDRESS_UPDATE_MUTATION } from '@/graphql/mutations/domiciliationForwardingAddressUpdate'

const ForwardingAddress = () => {
  const { t } = useTranslation('forwardingAddress')
  const { contact } = useContext(ContactContext)
  const { domiciliation } = useContext(DomiciliationContext)
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [validateState, setValidateState] = useState<boolean>(false)
  const [validate, setValidate] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [updateForwardingAddress] = useMutation(
    DOMICILIATION_FORWARDING_ADDRESS_UPDATE_MUTATION
  )

  const onFinish = (values) => {
    if (!loading) {
      setLoading(true)
      if (!validateState) {
        setValidateState(true)
        setLoading(false)
      } else {
        updateForwardingAddress({
          variables: {
            mailForwardingTargetName: values.name,
            streetAddress: values.address.streetAddress,
            postalCode: values.address.postalCode,
            city: values.address.city,
            country: values.address.countryCode || values.address.country,
            complement: values.complement
          }
        }).then(() => dispatch(nextPage()))
      }
    }
  }

  const releventDefaultAddress =
    domiciliation?.forwardingAddress ||
    contact?.personalInformation?.addressDetail
  const fullAddress =
    releventDefaultAddress.streetAddress &&
    releventDefaultAddress.city &&
    releventDefaultAddress.country
      ? releventDefaultAddress
      : undefined

  const initialValues = {
    name:
      domiciliation?.forwardingAddress?.name ||
      `${contact?.personalInformation?.contactDetail?.lastname} ` +
        contact?.personalInformation?.contactDetail?.firstname,
    address: {
      streetAddress: releventDefaultAddress.streetAddress,
      city: releventDefaultAddress.city,
      postalCode: releventDefaultAddress.postalCode,
      country: releventDefaultAddress.country
    }
  }

  return (
    <LegalflowSection title={t('title')}>
      <NoticeInformation icon={<img src={CSIcon} alt='circled star' />}>
        {t('notice.others')}
      </NoticeInformation>
      <Form
        name='forwardingAddressForm'
        className='mt-3'
        form={form}
        initialValues={initialValues}
        onFinish={(values) => onFinish(values)}
        requiredMark={false}
        layout='vertical'
      >
        <FormContent
          form={form}
          fullAddress={fullAddress}
          countryCode={contact?.countryConfig.countryCode}
          setValidate={setValidate}
          validateState={validateState}
          validate={validate}
        />
        <BasicButton
          testid='forwarding-address-next-button'
          bgColor='blue'
          label={t('button_label')}
          loading={loading}
          disabled={validateState && !validate}
          className='w-full justify-center body-1 font-semibold mt-4'
        />
      </Form>
    </LegalflowSection>
  )
}

export default ForwardingAddress
