import React from 'react'
import { useTranslation } from 'react-i18next'
import { BasicButton } from '@sedomicilier/material-sd'
import { ILegalFormality } from '@/interfaces/legalFormality'
import { ILegalDocument } from '@/interfaces/kycLogicRule'

const PartnerDepositLink = ({
  legalFormality
}: {
  legalFormality: ILegalFormality
}) => {
  const legalDocument: ILegalDocument | undefined =
    legalFormality.capitalDepositDocument
  const { t } = useTranslation()
  const navigateToPartner = () => {
    window.open(process.env['REACT_APP_PARTNER_DEPOSIT_URL'], '_blank')
  }

  const fileStateCard = (state: string) => {
    switch (state) {
      case 'in_error':
        return (
          <>
            <h5
              className={'text-sm font-medium mb-1'}
              style={{ color: '#CD100C' }}
            >
              {t('legal_formality:card.partnerDeposit.title.error')}
            </h5>
            <BasicButton
              bgColor='blue'
              label={t('legal_formality:card.partnerDeposit.btnLabel.error')}
              className='body-1 font-semibold !bg-primary-500 hover:!bg-primary-600'
              onClick={() => navigateToPartner()}
            />
          </>
        )
      case 'validated':
      case 'pending':
        return (
          <h5 className={'text-sm font-medium mb-1'}>
            {t(`legal_formality:card.capitalDeposit.title.${state}`)}
          </h5>
        )
      default:
        return (
          <>
            <h5 className={'text-sm font-medium text-center mb-1'}>
              {t('legal_formality:card.partnerDeposit.title.upload')}
            </h5>
            <BasicButton
              bgColor='blue'
              label={t('legal_formality:card.partnerDeposit.btnLabel.upload')}
              className='body-1 font-semibold !bg-primary-500 hover:!bg-primary-600'
              onClick={() => navigateToPartner()}
            />
          </>
        )
    }
  }

  return fileStateCard(legalDocument && legalDocument.state)
}

export default PartnerDepositLink
