import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import missing from 'src/images/information/info-orange.svg'
import clock from 'src/images/clock/clock-orange.svg'
import validated from 'src/images/check/green-check-circle.svg'
import denied from 'src/images/cross/cross-red-circle.svg'
import colors from '@/components/UIKit/colors'

export interface DocumentStepProps {
  state: string
}

const DocumentStep = ({ state }: DocumentStepProps) => {
  const { t } = useTranslation()
  const getRightState = () => {
    switch (state) {
      case 'expired':
        return (
          <StateNotice color={colors.error}>
            <DocumentIcon className='inline' src={denied} alt='expired' />
            {t('common:documentState.expired')}
          </StateNotice>
        )
      case 'formality':
        return (
          <StateNotice color={colors.success}>
            <DocumentIcon className='inline' src={validated} alt='géré' />
            {t('common:documentState.formality')}
          </StateNotice>
        )
      case 'in_error':
        return (
          <StateNotice color={colors.error}>
            <DocumentIcon className='inline' src={denied} alt='refusé' />
            {t('common:documentState.denied')}
          </StateNotice>
        )
      case 'missing':
        return (
          <StateNotice color={colors.orange}>
            <DocumentIcon className='inline' src={missing} alt='manquant' />
            {t('common:missing')}
          </StateNotice>
        )
      case 'pending':
        return (
          <StateNotice color={colors.orange}>
            <DocumentIcon className='inline' src={clock} alt='verification' />
            {t('common:documentState.verification')}
          </StateNotice>
        )
      case 'pending_check_ondorse':
        return (
          <StateNotice color={colors.orange}>
            <DocumentIcon className='inline' src={clock} alt='verification' />
            {t('common:documentState.verification')}
          </StateNotice>
        )
      case 'pending_upload_id_now':
        return (
          <StateNotice color={colors.orange}>
            <DocumentIcon className='inline' src={clock} alt='verification' />
            {t('common:documentState.verification')}
          </StateNotice>
        )
      case 'pending_upload_ondorse':
        return (
          <StateNotice color={colors.orange}>
            <DocumentIcon className='inline' src={clock} alt='verification' />
            {t('common:documentState.verification')}
          </StateNotice>
        )
      case 'validated':
        return (
          <StateNotice color={colors.success}>
            <DocumentIcon className='inline' src={validated} alt='validé' />
            {t('common:documentState.validated')}
          </StateNotice>
        )
    }
    return
  }

  return <DocumentStepWrapper>{getRightState()}</DocumentStepWrapper>
}

export const DocumentStepWrapper = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
`
export const DocumentIcon = styled.img`
  margin-right: 4px;
  width: 14px;
  height: 14px;
`
export const StateNotice = styled.div`
  color: ${(props) => props.color};
`
export default DocumentStep
