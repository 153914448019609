import i18n from 'i18next'

import moment from 'moment'
import 'moment/locale/fr'

import fr from 'src/locales/fr'
import en from 'src/locales/en'
import zh from 'src/locales/zh'
const resources = {
  en: {
    accounting: en.legalFlow.accounting,
    cancelationProcedure: en.cancelation_procedure,
    capital: en.legalFlow.capital,
    cgvus: en.cgvus,
    common: en.common,
    company: en.legalFlow.company,
    dashboard: en.dashboard,
    demo: en.demo,
    domiciliationPage: en.domiciliation_page,
    enterprise: en.enterprise,
    invoices: en.invoices,
    forwardingAddress: en.legalFlow.forwarding_address,
    legal_formality: en.legal_formality,
    legalRepresentative: en.legalFlow.legal_representative,
    mobileBreadcrumb: en.mobile_breadcrumb,
    nav: en.nav,
    navigation: en.navigation,
    notifications: en.notifications,
    onboarding: en.legalFlow.onboarding,
    partner: en.legalFlow.partner,
    payments: en.payments,
    procedure: en.procedure,
    products: en.products,
    profile: en.profile,
    referrer: en.referrer,
    security: en.security,
    services: en.services,
    subscription: en.subscription,
    summary: en.legalFlow.summary,
    team: en.team,
    upsell: en.upsell,
    userMails: en.user_mails
  },
  fr: {
    accounting: fr.legalFlow.accounting,
    cancelationProcedure: fr.cancelation_procedure,
    capital: fr.legalFlow.capital,
    cgvus: fr.cgvus,
    common: fr.common,
    company: fr.legalFlow.company,
    dashboard: fr.dashboard,
    demo: fr.demo,
    domiciliationPage: fr.domiciliation_page,
    enterprise: fr.enterprise,
    invoices: fr.invoices,
    forwardingAddress: fr.legalFlow.forwarding_address,
    legal_formality: fr.legal_formality,
    legalRepresentative: fr.legalFlow.legal_representative,
    mobileBreadcrumb: fr.mobile_breadcrumb,
    nav: fr.nav,
    navigation: fr.navigation,
    notifications: fr.notifications,
    onboarding: fr.legalFlow.onboarding,
    partner: fr.legalFlow.partner,
    payments: fr.payments,
    procedure: fr.procedure,
    products: fr.products,
    profile: fr.profile,
    referrer: fr.referrer,
    security: fr.security,
    services: fr.services,
    subscription: fr.subscription,
    summary: fr.legalFlow.summary,
    team: fr.team,
    upsell: fr.upsell,
    userMails: fr.user_mails
  },
  zh: {
    accounting: zh.legalFlow.accounting,
    cancelationProcedure: zh.cancelation_procedure,
    capital: zh.legalFlow.capital,
    cgvus: zh.cgvus,
    common: zh.common,
    company: zh.legalFlow.company,
    dashboard: zh.dashboard,
    demo: zh.demo,
    domiciliationPage: zh.domiciliation_page,
    enterprise: zh.enterprise,
    invoices: zh.invoices,
    forwardingAddress: zh.legalFlow.forwarding_address,
    legal_formality: zh.legal_formality,
    legalRepresentative: zh.legalFlow.legal_representative,
    mobileBreadcrumb: zh.mobile_breadcrumb,
    nav: zh.nav,
    navigation: zh.navigation,
    notifications: zh.notifications,
    onboarding: zh.legalFlow.onboarding,
    partner: zh.legalFlow.partner,
    payments: zh.payments,
    procedure: zh.procedure,
    products: zh.products,
    profile: zh.profile,
    referrer: zh.referrer,
    security: zh.security,
    services: zh.services,
    subscription: zh.subscription,
    summary: zh.legalFlow.summary,
    team: zh.team,
    upsell: zh.upsell,
    userMails: zh.user_mails
  }
}

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18n.init(
  {
    resources,
    ns: Object.keys(resources.fr),
    defaultNS: 'common',
    returnNull: false,
    lng: 'fr',
    fallbackLng: 'en',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      format(value, format, lng) {
        if (value instanceof Date) {
          return moment(value).format(format)
        } else if (format === 'currency') {
          if (value === null) {
            return 'N/A'
          }

          if (value === 0 || value === 0.0) {
            if (lng === 'fr') {
              return 'gratuit'
            } else {
              return 'free'
            }
          }

          return new Intl.NumberFormat(lng, {
            style: 'currency',
            currency: 'EUR'
          }).format(value)
        }
        return value
      }
    }
  },
  () => {
    moment.locale('fr')
  }
)
i18n.on('languageChanged', (lng) => {
  moment.locale(lng)
})

export default i18n
