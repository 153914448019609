import styled from 'styled-components'
import { Drawer } from 'antd'
import { useTranslation } from 'react-i18next'
import { DrawerTitle } from '../ProcedureDrawer/styles'
import {
  ChoiceIcon,
  ChoiceTitle,
  ChoiceWrapper
} from '../IdentityProofDrawer/identitySelector'
import { useState } from 'react'
import Button from 'src/components/UIKit/components/Button/buttonWithBorder'
import idCardIcon from 'src/images/legal-documents/drawer/IdentityCardFrench-recto.svg'
import passportIcon from 'src/images/legal-documents/drawer/PassportFrench-recto.svg'
import { Choice } from '../identityDrawers'
import ArrowLeftBlack from 'src/images/arrow/arrow-left-black-reactnode'
import { Colors } from 'src/components/UIKit'
import BOPhysical from './BOPhysical'
import BOMoral from './BOMoral'

const BeneficialOwnerDrawer = ({
  onClose,
  open,
  index,
  states,
  onSubmit,
  setInfo,
  info,
  selectedDenomination,
  setSelectedDenomination,
  denominationChoices,
  contactDetail,
  moralShareholderName
}) => {
  const { t } = useTranslation()
  const { setSelectedFile, setFileType, setFileVerso } = states

  const identityChoices: Choice[] = [
    {
      name: 'idCard',
      icon: idCardIcon,
      title: t('procedure:identity.nationalCard'),
      fileType: 'legalDocument_kyc_identityCard'
    },
    {
      name: 'passport',
      icon: passportIcon,
      title: t('procedure:identity.passport'),
      fileType: 'legalDocument_kyc_passport'
    }
  ]

  const [selectIdentity, setSelectIdentity] = useState<any>(identityChoices[0])
  const [confirmDenomination, setConfirmDenomination] = useState(false)
  const [confirmIdentity, setConfirmIdentity] = useState(false)

  const getDrawerTitle = () => {
    if (moralShareholderName) {
      return t('procedure:beneficialOwner.drawer.sectionTitleWithName', {
        name: moralShareholderName
      })
    }
    if (contactDetail.lastname && contactDetail.firstname) {
      return t('procedure:beneficialOwner.drawer.sectionTitleWithName', {
        name: `${contactDetail.firstname} ${contactDetail.lastname}`
      })
    }
    return t('procedure:beneficialOwner.drawer.sectionTitle', {
      number: index
    })
  }

  const onConfirm = () => {
    if (selectedDenomination) {
      return setConfirmDenomination(true)
    }
    return
  }
  const onCancelDenomination = () => {
    setSelectIdentity(null)
    setSelectedDenomination(null)
    onClose()
  }

  const handleClose = () => {
    if (confirmIdentity) {
      setSelectedFile(null)
      setFileVerso(null)
      return setConfirmIdentity(false)
    } else if (confirmDenomination) {
      setSelectedFile(null)
      return setConfirmDenomination(false)
    } else {
      return onClose()
    }
  }

  const handleSelect = (choice) => {
    if (choice.fileType === 'moral') {
      setFileType('legalDocument_kyc_moraleKbis')
    }
    return setSelectedDenomination(choice)
  }

  return (
    <Drawer
      title={getDrawerTitle()}
      placement='right'
      onClose={handleClose}
      open={open}
      zIndex={1000}
      width={400}
      closeIcon={<ArrowLeftBlack />}
      headerStyle={{ textAlign: 'center', marginRight: '28px' }}
    >
      {!confirmDenomination ? (
        <>
          <DrawerTitle>{getDrawerTitle()}</DrawerTitle>
          <BODenominationChoice>
            {denominationChoices.map((choice) => {
              return (
                <ChoiceWrapper
                  key={choice.name}
                  selected={selectedDenomination?.name === choice?.name}
                  onClick={() => handleSelect(choice)}
                >
                  <ChoiceIcon src={choice.icon} alt={`${choice.icon} icone`} />
                  <ChoiceTitle>{choice.name}</ChoiceTitle>
                </ChoiceWrapper>
              )
            })}
          </BODenominationChoice>
          {setSelectedDenomination && (
            <ButtonWrapper>
              <Button
                bgColor='blue'
                height='48px'
                justifyContent='center'
                onClick={onConfirm}
              >
                {t('common:continue')}
              </Button>
              <Button
                bgColor='white'
                height='48px'
                justifyContent='center'
                onClick={onCancelDenomination}
              >
                {t('common:cancel')}
              </Button>
            </ButtonWrapper>
          )}
        </>
      ) : selectedDenomination.fileType === 'physical' ? (
        <BOPhysical
          identityChoices={identityChoices}
          selectIdentity={selectIdentity}
          setSelectIdentity={setSelectIdentity}
          states={states}
          onSubmit={onSubmit}
          confirmIdentity={confirmIdentity}
          setConfirmIdentity={setConfirmIdentity}
          setConfirmDenomination={setConfirmDenomination}
          setInfo={setInfo}
          info={info}
        />
      ) : (
        <BOMoral
          states={states}
          onSubmit={onSubmit}
          setConfirmDenomination={setConfirmDenomination}
          contactDetail={contactDetail}
        />
      )}
    </Drawer>
  )
}

export const BODenominationChoice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const Label = styled.div`
  color: ${Colors.black};
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 500;
`
export const TextInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
`

export default BeneficialOwnerDrawer
