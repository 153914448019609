import { SumUpPurchases } from '@/app/Loyalty/Payment/summary'
import OrderSummary from '@/components/payment/summary/orderSummary'
import PaymentFrequency from '@/components/payment/summary/paymentFrequency'
import { Frequency, ICart } from '@/interfaces/cart'
import { IProduct } from '@/interfaces/product'
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit'
import { Dispatch, SetStateAction } from 'react'

export interface PaymentSummaryComponentProps {
  purchases: SumUpPurchases
  promoPurchase?: IProduct
  nextPage: ActionCreatorWithOptionalPayload<string | undefined, string>
  allCarts: ICart[]
  currentCart: ICart
  frequency: string | undefined
  setFrequency: Dispatch<SetStateAction<Frequency | undefined>>
  applyBillingCycleLoading: boolean
}

const PaymentSummaryComponent: React.FC<PaymentSummaryComponentProps> = ({
  purchases,
  promoPurchase,
  nextPage,
  allCarts,
  currentCart,
  frequency,
  setFrequency,
  applyBillingCycleLoading
}) => {
  return (
    <div className='flex gap-2 mobile:flex-col mobile:items-center tablet:flex-col tablet:items-center wide:flex-row wide:justify-center wide:items-start'>
      <PaymentFrequency
        allCarts={allCarts}
        frequency={frequency}
        setFrequency={setFrequency}
      />
      <OrderSummary
        purchases={purchases}
        promoPurchase={promoPurchase}
        nextPage={nextPage}
        htAmount={currentCart.amount}
        deductibleVAT={currentCart.amountVat}
        ttcAmount={currentCart.amountWithVat}
        applyBillingCycleLoading={applyBillingCycleLoading}
      />
    </div>
  )
}

export default PaymentSummaryComponent
