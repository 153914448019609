import { PaymentElement } from '@stripe/react-stripe-js'
import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

interface AddCardProps {}

const AddCard: React.FC<AddCardProps> = () => {
  const { t } = useTranslation('payments')
  return (
    <div className='mt-4'>
      <Form.Item
        name='cardName'
        label={t('billing.cardDetails.cardName')}
        rules={[
          {
            required: true,
            message: t('billing.cardDetails.validation.cardName')
          }
        ]}
      >
        <Input
          data-testid='card-name-input'
          placeholder={t('billing.cardDetails.cardName')}
        />
      </Form.Item>
      <PaymentElement />
    </div>
  )
}

export default AddCard
