import { IPaymentSource } from '@/interfaces/paymentSource'
import { Form, Radio, Space } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface RegisteredCardsProps {
  cards: IPaymentSource[]
}

const RegisteredCards: React.FC<RegisteredCardsProps> = ({ cards }) => {
  const { t } = useTranslation()

  const defaultCard = cards.find(
    (card: IPaymentSource) => card.currentSource
  )?.token

  const [paymentSource, setPaymentSource] = useState<
    IPaymentSource['token'] | undefined
  >(defaultCard)

  const onChange = (id) => {
    setPaymentSource(id)
  }

  return (
    <Form.Item name='paymentSource' className='mb-4'>
      <Radio.Group
        onChange={(e) => onChange(e.target.value)}
        value={paymentSource}
        className='[&_.ant-radio-inner]:!h-[20px] [&_.ant-radio-inner]:!w-[20px]'
      >
        <Space direction='vertical'>
          {cards.map((card: IPaymentSource) => (
            <Radio value={card.token} key={card.id} className='flex gap-2'>
              <div className='flex justify-between items-center gap-2'>
                <span className='ico-card text-s' />
                <div className='flex flex-col'>
                  <span className='body-2'>
                    {t('payments:billing.cardDetails.endingBy', {
                      last4Digits: card.last4Digits
                    })}
                  </span>
                  <span className='text-7 text-neutral-600'>
                    {t('payments:billing.cardDetails.expire', {
                      date: new Date(card.expirationDate)
                    })}
                  </span>
                </div>
              </div>
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Form.Item>
  )
}

export default RegisteredCards
