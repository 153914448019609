import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ReactNode } from 'react'

interface StripeLayoutProps {
  children: ReactNode
  paymentInformations: {
    stripeAccount?: string
    clientSecret?: string
  }
  locale: string
}

interface StripeOptions {
  clientSecret?: string
  loader: 'auto' | 'always' | 'never' | undefined
}

const StripeLayout: React.FC<StripeLayoutProps> = ({
  children,
  paymentInformations,
  locale
}) => {
  const options: StripeOptions = paymentInformations.clientSecret
    ? { clientSecret: paymentInformations.clientSecret, loader: 'auto' }
    : { loader: 'auto' }

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
    {
      stripeAccount: paymentInformations.stripeAccount,
      locale: locale as 'fr' | 'en'
    }
  )

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  )
}

export default StripeLayout
