import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProgressBar } from 'src/components/UIKit'
import { FormButton, StatusWrapper } from './styles'
import { ILegalFormality } from '@/interfaces/legalFormality'
import { Card } from 'antd'
import LegalDepositUpload from './LegalDepositUpload'
import PartnerDepositLink from '@/CustomerArea/services/LegalFormalities/PartnerDepositLink'

interface IProps {
  initialLegalFormality: ILegalFormality
  tileWidth: number
}

const Status = (props: IProps) => {
  const INDY_DEPOSIT_URL = 'https://app.indy.fr/inscription/depot-de-capital'
  const { t } = useTranslation('products')
  const { initialLegalFormality, tileWidth } = props
  const isTypeform = initialLegalFormality.formProvider === 'typeform'
  const isAborted = initialLegalFormality.step == 'flow_aborted'
  const abortedSteps = ['flow_aborted']

  const currentIndex = initialLegalFormality.steps.findIndex(
    (step) => step === initialLegalFormality.step
  )
  const current = isAborted ? 0 : currentIndex
  const steps = isAborted ? abortedSteps : initialLegalFormality.steps

  const items = steps.map((step) => {
    const isCapitalDeposit =
      ['waiting_capital_deposit', 'capital_deposit_uploaded'].indexOf(
        initialLegalFormality.status
      ) != -1
    let note =
      !initialLegalFormality.formUrl &&
      t(`status.${initialLegalFormality.status}.note`, '')
    isCapitalDeposit && !isTypeform && (note = '')
    isCapitalDeposit && (note = '')

    return { title: t(`steps.${step}.title`), note: note || '' }
  })

  const navToFormUrl = () => window.open(initialLegalFormality.formUrl)

  return (
    <StatusWrapper tileWidth={tileWidth}>
      <h4>{t(`title.${initialLegalFormality.identificationToken}`)}</h4>

      <ProgressBar current={current} items={items} />

      {initialLegalFormality.formUrl && (
        <Card style={{ width: '100%', textAlign: 'end' }}>
          <FormButton size='large' shape='round' onClick={navToFormUrl}>
            {t('actions.fill_form')}
          </FormButton>
        </Card>
      )}

      {/* PARTNER CAPITAL DEPOSIT */}
      {initialLegalFormality.step == 'waiting_capital_deposit' &&
        initialLegalFormality.isPartnerDeposit && (
          <Card style={{ width: '100%', textAlign: 'end' }}>
            <FormButton
              size='large'
              shape='round'
              onClick={window.open(INDY_DEPOSIT_URL)}
            >
              {t('actions.partner_deposit')}
            </FormButton>
          </Card>
        )}

      {/* UPLOAD CAPITAL DEPOSIT */}
      {initialLegalFormality.step == 'capital_deposit_uploaded' &&
        initialLegalFormality.capitalDepositDocument && (
          <Card className={'upload-card'}>
            {initialLegalFormality.isPartnerDeposit ? (
              <PartnerDepositLink legalFormality={initialLegalFormality} />
            ) : (
              <LegalDepositUpload legalFormality={initialLegalFormality} />
            )}
          </Card>
        )}
    </StatusWrapper>
  )
}

export default Status
