// @ts-nocheck
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Modal } from 'antd'
import { ModalBodyWrapper, Intro } from './styles'

interface IProps {
  legalFormalityToken: string | undefined
  closeModal: () => void
}

const InformationModal = (props: IProps) => {
  const { t } = useTranslation()
  const { legalFormalityToken, closeModal } = props

  return (
    <Modal
      centered={true}
      open={legalFormalityToken !== undefined}
      footer={null}
      closable={true}
      onCancel={() => closeModal()}
      wrapClassName='legal-formality-information-modal'
    >
      <ModalBodyWrapper>
        <h4>{t(`products:${legalFormalityToken}.informations.title`)}</h4>
        <Trans
          t={t}
          i18nKey={`products:${legalFormalityToken}.informations..text`}
        >
          <Intro>
            {t(`products:${legalFormalityToken}.informations.intro`)}
          </Intro>
          <h6>
            {t(`products:${legalFormalityToken}.informations.section1.title`)}
          </h6>
          <ul>
            {t(`products:${legalFormalityToken}.informations.section1.content`)}
          </ul>
          <h6>
            {t(`products:${legalFormalityToken}.informations.section2.title`)}
          </h6>
          <ul>
            {t(`products:${legalFormalityToken}.informations.section2.content`)}
          </ul>
          {/^assistance-juridique/.test(legalFormalityToken) && (
            <h6>{t(`products:${legalFormalityToken}.informations.outro`)}</h6>
          )}
        </Trans>
      </ModalBodyWrapper>
    </Modal>
  )
}

export default InformationModal
