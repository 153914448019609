import { useEffect, useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import { DomiciliationContext } from '@/contexts/DomiciliationContext'
import CustomerArea from './CustomerArea'
import LegalFlow from './LegalFlow'
import Loyalty from '@/app/Loyalty'

const PrivateRouting = () => {
  const { domiciliation } = useContext(DomiciliationContext)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const pathname = location.pathname

    if (
      domiciliation &&
      !domiciliation.legalflowDone &&
      !pathname.includes('legal-flow')
    ) {
      navigate('/legal-flow')
    }
  }, [])

  return (
    <Routes>
      {!domiciliation?.legalflowDone && (
        <Route path='/legal-flow/*' element={<LegalFlow />} />
      )}
      <Route path='/loyalty/*' element={<Loyalty />} />
      <Route path='/*' element={<CustomerArea />} />
    </Routes>
  )
}

export default PrivateRouting
