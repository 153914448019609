import LoyaltyLayout from '@/app/Loyalty/layout'
import Offer from '@/app/Loyalty/Offer'
import PaymentSummary from '@/app/Loyalty/Payment/summary'
import Footer from '@/components/layouts/Footer'
import NakedHeader from '@/components/layouts/Header/nakedHeader'
import { useWindowDimensions } from '@/components/shared/useWindowDimensions'
import Navigation from '@/LegalFlow/Navigation'
import { loyaltyModules } from '@/workflows/loyaltyModules'
import { TailwindConfig } from '@sedomicilier/material-sd'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from '@/hooks/redux'
import { previousPage, setPath } from '@/reducers/loyaltyFlow'
import BillingLayout from '@/app/Loyalty/Payment/billing/layout'
import ProcessingLayout from '@/app/Loyalty/Payment/processing/layout'
import { DomiciliationContext } from '@/contexts/DomiciliationContext'
import { useContext, useEffect } from 'react'
import Routing from '@/CustomerArea/Routing'

const Loyalty: React.FC = () => {
  const { domiciliation } = useContext(DomiciliationContext)
  const { width } = useWindowDimensions()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const processingPage = pathname.includes('/loyalty/payment/processing')
  const flow = useSelector((state) => state.loyaltyFlow.flow)
  const mobile =
    width <= parseInt(TailwindConfig.breakpointsCustomerArea.mobile.max, 10)

  useEffect(() => {
    if (!domiciliation.canUpsell) navigate(Routing.ROOT)
  }, [domiciliation.canUpsell])

  if (!domiciliation.canUpsell) {
    return null
  }

  return (
    <main className='bg-neutral-50 p-0 h-screen overflow-y-auto flex flex-col justify-between'>
      <LoyaltyLayout>
        <NakedHeader />
        <div className='flex flex-1 items-start tablet:px-5 mobile:px-2 pb-5'>
          {!mobile && !processingPage && (
            <Navigation
              steps={loyaltyModules}
              translation='loyalty'
              flow={flow}
              previousPage={previousPage}
              setPath={setPath}
            />
          )}
          <Routes>
            <Route path='offer' element={<Offer />} />
            <Route path='payment/summary' element={<PaymentSummary />} />
            <Route path='payment/billing' element={<BillingLayout />} />
            <Route path='payment/processing' element={<ProcessingLayout />} />
          </Routes>
        </div>
      </LoyaltyLayout>
      <Footer />
    </main>
  )
}

export default Loyalty
